import React, { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
    IconButton,
    Avatar,
    Flex,
    HStack,
    Text,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Portal,
    Image
} from '@chakra-ui/react';
import {
    FiBell,
    FiExternalLink,
    FiInfo,
    FiMail,
    FiSearch,
    FiUserPlus
} from 'react-icons/fi';
import { FiUser, FiPower, FiAward } from 'react-icons/fi'
import { AuthContext } from "../App.js"
import { auth } from '../config/firebaseClient';
import { signOut } from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import lg from "../three-pr5.png"

const MobileNav = ({ ...rest }) => {
    const navigate = useNavigate();
    const [notifCount, setNotifCount] = useState(0)

    const [authData, setAuthData] = useContext(AuthContext)

    const signout = async () => {
        signOut(auth).then(() => {
            toast.success("Successfully signed out, redirecting to sign in page.", {
                autoClose: 2000,
                hideProgressBar: true
            })
            navigate('/signin')

        }).catch((error) => {
            console.error(error)
            toast.error("Error when signing out, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
        });
        //don't need to clear context here because signout will trigger SIGNED_OUT event and clear the auth and global context within MainInit.js
    }

    return (
        <Flex
            fontFamily="Futura"
            ml={{ base: 0 }}
            px={{ base: 4 }}
            height="20"
            /* was position: fixed before, removed due to content being shown under it */
            width="100vw"
            alignItems="center"
            bg='#1C1C1C'
            borderBottomWidth="1px"
            justifyContent={{ base: 'space-between' }}
            {...rest}>
            <Menu>
            <Link to='/search?type=profile'>
                <MenuButton>
                    <IconButton
                        display={{ base: 'flex' }}
                        variant="outline"
                        aria-label="open menu"
                        color='#ECECEC'
                        size='md'
                        
                        _hover={{ bgColor: '#1c1b1b' }}
                        icon={<FiSearch />}
                    />
                    
                </MenuButton>
                </Link>
                {null/*<Portal>
                    <MenuList bgColor="#121212">
                        <MenuItem icon={<FiUser size={20} />} onClick={() => setSearchProfileModal(true)} bgColor="#121212"> <Text as='b' fontFamily="Futura">Users</Text> </MenuItem>
                        <MenuItem icon={<FiDollarSign size={20} />} onClick={() => setSearchStockModal(true)} bgColor="#121212"> <Text as='b' fontFamily="Futura">Stocks</Text> </MenuItem>
                    </MenuList>
                </Portal>*/}
            </Menu>

            <Link to={authData.firebaseuser ? "/feed" : ""}>
                <Flex>
                        <Image mr={1} src={lg} htmlWidth='230px'/>
                    <Text
                        display={{ base: 'flex' }}
                        fontSize="2xl"
                        fontFamily="Futura"
                        fontWeight="bold"
                        opacity={0.85}
                        _hover={{ cursor: 'pointer' }}>
                        
                    </Text>
                </Flex>
                
            </Link>
            <HStack spacing={{ base: '0' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}
                        >
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src={authData.firebaseuser !== null ? authData.firebaseuser.photoURL : ""}
                                    bg=''
                                />

                            </HStack>
                        </MenuButton>
                        <Portal>


                            {
                                authData.firebaseuser === null ? (
                                    <MenuList bgColor="#1C1C1C" zIndex={2}>
                                        <Link to="/signin"> <MenuItem bgColor="#1C1C1C" icon={<FiUser size={20} />}><Text as='b' fontFamily="Futura">Sign In</Text></MenuItem> </Link>
                                        <Link to="/signup"> <MenuItem bgColor="#1C1C1C" icon={<FiUserPlus size={20} />}><Text as='b' fontFamily="Futura">Sign Up</Text></MenuItem> </Link>
                                        <MenuDivider />
                                        <Link to="/leaderboards">  <MenuItem bgColor="#1C1C1C" icon={<FiAward size={20} />}><Text as='b' fontFamily="Futura">Leaderboards</Text></MenuItem> </Link>
                                        <Link to="/info"> <MenuItem bgColor="#1C1C1C" icon={<FiInfo size={20} />}><Text as='b' fontFamily="Futura">Info</Text></MenuItem> </Link>

                                    </MenuList>
                                ) : (
                                        <MenuList bgColor="#1C1C1C" zIndex={2}>
                                            <Link to='/profile'> <MenuItem bgColor="#1C1C1C" icon={<FiUser size={20} />}><Text as='b' fontFamily="Futura">Profile</Text></MenuItem> </Link>
                                            <Link to="/notifications">
                                                <MenuItem bgColor="#1C1C1C" icon={<FiBell size={20} />}>
                                                    <Text as='b' fontFamily="Futura">Notifications {notifCount > 0 ? (notifCount > 9 ? "(9+)" : `(${notifCount})`) : ''}</Text>
                                                </MenuItem>
                                            </Link>
                                            <Link to="/leaderboards"> <MenuItem bgColor="#1C1C1C" icon={<FiAward size={20} />}><Text as='b' fontFamily="Futura">Leaderboards</Text></MenuItem> </Link>
                                            <Link to="/info"> <MenuItem bgColor="#1C1C1C" icon={<FiInfo size={20} />}><Text as='b' fontFamily="Futura">Info</Text></MenuItem> </Link>

                                            <MenuDivider />
                                            <MenuItem color="red.200" bgColor="#1C1C1C" icon={<FiPower size={20} />} onClick={signout}><Text as='b' fontFamily="Futura">Sign Out</Text></MenuItem>
                                        </MenuList>
                                    )
                            }
                        </Portal>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
}

export default MobileNav
//onClick={() => location.pathname !== `/${authData.loggedUser.username}` ? window.history.replaceState({}, '', `${location.pathname}`) : null}