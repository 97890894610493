import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    Spinner,
    Checkbox
} from '@chakra-ui/react';
import { useState, useEffect, useContext, useRef } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { auth } from '../config/firebaseClient';
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../App.js"
import { signInWithEmailAndPassword } from "firebase/auth";
import { Helmet } from "react-helmet"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [email, setEmail] = useState('')
    const [fName, setfName] = useState('')
    const [lName, setlName] = useState('')
    const [username, setUsername] = useState('')
    const [loading, setLoading] = useState(false)
    const [initLoading, setInitLoading] = useState(true)

    const [isChecked, setIsChecked] = useState(false);
    const recaptcha = useRef(null);

    const navigate = useNavigate();

    const [authData, setAuthData] = useContext(AuthContext)

    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }

    useEffect(() => {

        if (authData.firebaseuser) {
            navigate("/feed")
            return
        }
        setInitLoading(false)

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // Remove event listener on component unmount
            document.removeEventListener("keydown", handleKeyDown);
        };

    }, [authData])

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            document.getElementById("signUpButton").click();
        }
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const signUp = async () => {
        setLoading(true)
        if (fName === "" || lName === "" || email === "" || username === "" || password === "" || confPassword === "") {
            toast.error("Fill out all required fields to register.", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            return
        }
        if (password !== confPassword) {
            toast.error("Passwords do not match.", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            return
        }
        if (!isChecked) {
            toast.error("You must accept the Terms and Conditions", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            return
        }

        const captchaValue = await recaptcha.current.getValue();
        if (!captchaValue) {
            toast.error("Please verify the reCAPTCHA", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            return
        } 

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/registeruser`,
                {
                    method: 'PUT',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        username: username,
                        fname: fName,
                        lname: lName,
                        password: password,
                        email: email,
                        captchaValue: captchaValue
                    })
                }
            )
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to register user", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }

            const error = await response.json();
            console.error(error)

            toast.error(`${error.message}`, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            if (recaptcha.current) {
                recaptcha.current.reset()
            }
            return
        }
        else {
            toast({
                title: `Successfully registered!`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    console.log(user)
                    navigate("/profile")
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    toast.error(`${error.message.replaceAll("Firebase: ", "")}`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                });

            setLoading(false)
        }
    }

    if (initLoading) {
        return <Flex
            align={'center'}
            justify={'center'}
            minH={'100vh'}>
            <Spinner
                thickness='4px'
                speed='1s'
                color='#949FFA'
                size='xl'
            />
        </Flex>
    }

    return (
        <Flex fontFamily="Futura">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Sign up</title>
                <link rel="canonical" href="https://www.bloomtrades.net/signup" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Stack spacing={6} mx={'auto'} maxW={'lg'} py={6} px={3}>
                <Stack align={'center'}>
                    <Heading fontFamily="Futura" fontSize={'4xl'} textAlign={'center'}>
                        Sign up
                </Heading>
                    <Text as='b' fontSize={'md'} opacity={0.85}>
                        Join the rising community of investors 🚀
                </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg='#282828'
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={2}>
                        <HStack>
                            <Box>
                                <FormLabel fontWeight='bold' fontFamily="Futura">First Name</FormLabel>
                                <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" type="text" value={fName} onChange={(e) => setfName(e.target.value)} />
                            </Box>
                            <Box>
                                <FormLabel fontWeight='bold' fontFamily="Futura" >Last Name</FormLabel>
                                <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" type="text" value={lName} onChange={(e) => setlName(e.target.value)} />
                            </Box>
                        </HStack>
                        
                        <FormLabel fontWeight='bold' fontFamily="Futura">Username</FormLabel>
                        <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    
                    
                        <FormLabel fontWeight='bold' fontFamily="Futura">Email address</FormLabel>
                        <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        
                        <FormLabel fontWeight='bold' fontFamily="Futura">Password</FormLabel>
                        <InputGroup>
                            <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' value={password} type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                            <InputRightElement h={'full'}>
                                <Button
                                    borderRadius={10}
                                    variant={'ghost'}
                                    onClick={() =>
                                        setShowPassword((showPassword) => !showPassword)
                                    }>
                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>

                        <FormLabel fontWeight='bold' fontFamily="Futura">Confirm Password</FormLabel>
                        <InputGroup>
                            <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" value={confPassword} type={showConfPassword ? 'text' : 'password'} onChange={(e) => setConfPassword(e.target.value)} />
                            <InputRightElement h={'full'}>
                                <Button
                                    borderRadius={10}
                                    variant={'ghost'}
                                    onClick={() =>
                                        setShowConfPassword((showConfPassword) => !showConfPassword)
                                    }>
                                    {showConfPassword ? <ViewIcon /> : <ViewOffIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>

                        <Checkbox pt={2} isChecked={isChecked} onChange={handleCheckboxChange} colorScheme='purple'>
                            <Text>
                                By signing up, you understand that the content shared here does not constitute financial advice and I have read and accept the
                                <Link to="/privacy-policy">
                                    <Text as="span" color={'#949FFA'}> Privacy Policy </Text>
                                </Link>
                                and
                                <Link to="/terms-and-conditions">
                                    <Text as="span" color={'#949FFA'}> Terms and Conditions</Text>
                                </Link>
                            </Text>
                        </Checkbox>

                        <Flex pt={2} mx={'auto'} justifyContent="center">
                            <ReCAPTCHA sitekey="6Lcuc28pAAAAAI8r8M1xG5FPwxDnNU1b95bEQd88" ref={recaptcha}/>
                        </Flex>

                        <Stack spacing={10} pt={2}>
                            <Button
                                id="signUpButton"
                                borderRadius={10}
                                disabled={loading}
                                isLoading={loading}
                                onClick={signUp}
                                loadingText="Signing up..."
                                size="md"
                                bg='#949ffa'
                                color="#1C1C1C"
                                _hover={{color: '#1C1C1C', bg: '#949ffa'}}
                                >
                                Sign up
                        </Button>
                        </Stack>
                        <HStack pt={2} spacing={1}>
                            <Text as='b' fontFamily="Futura" align={'center'}>Already a user?</Text>
                            <Link to='/signin'><Text as='b' color={'#949FFA'}>Sign in</Text> </Link>
                        </HStack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}

export default Signup