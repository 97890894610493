import React, { useEffect, useContext } from 'react';
import { auth } from '../config/firebaseClient';
import SidebarWithHeader from "./SidebarWithHeader"
import {
    Flex,
    Spinner
} from '@chakra-ui/react';
import { useSearchParams } from "react-router-dom";
import { AuthContext, GlobalContext } from "../App.js"

const MainInit = () => {
    const { username } = useSearchParams()
    const [authData, setAuthData] = useContext(AuthContext)
    const [globalData, setGlobalData] = useContext(GlobalContext)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in
                //setUser(user);
                console.log("SIGN IN")

                setAuthData({ loading: false, firebaseuser: user})
                setGlobalData({
                    userInfo: {},
                    allPosts: {},
                    symbolStats: {},
                    feedSettings: {
                        paidPosts: false,
                        predictionPosts: false,
                        openPosts: false
                    },
                    profileSelfSettings: {
                        paidPosts: false,
                        predictionPosts: false,
                        openPosts: false
                    },
                    symbolFeedSettings: {
                        paidPosts: {}, //each key will symbol and if symbol is in there means paidOnly=true
                        isAdvanced: {}
                    },
                    profileSettings: {
                        paidPosts: {}, //each key will symbol and if symbol is in there means paidOnly=true
                        predictionPosts: {},
                        openPosts: {}
                    },
                    notifications: null,
                    notificationSettings: {
                        CLOSE: true,
                        FOLLOW: true,
                        POST: true,
                        PRICE_CHANGE: true,
                        UPVOTE: true,
                        PRICE_CHANGE_FAIL: true,
                        REPLY: true
                    }
                })
            }
            else {
                // User is signed out / no user

                console.log("SIGN OUT")
                setAuthData({ ...authData, loading: false, firebaseuser: null })
                setGlobalData({
                    userInfo: {},
                    allPosts: {},
                    symbolStats: {},
                    feedSettings: {
                        paidPosts: false,
                        predictionPosts: false,
                        openPosts: false
                    },
                    profileSelfSettings: {
                        paidPosts: false,
                        predictionPosts: false,
                        openPosts: false
                    },
                    symbolFeedSettings: {
                        paidPosts: {}, //each key will symbol and if symbol is in there means paidOnly=true
                        isAdvanced: {}
                    },
                    profileSettings: {
                        paidPosts: {}, //each key will user and if user is in there means paidOnly=true
                        predictionPosts: {},
                        openPosts: {}
                    },
                    notifications: null,
                    notificationSettings: {
                        CLOSE: true,
                        FOLLOW: true,
                        POST: true,
                        PRICE_CHANGE: true,
                        UPVOTE: true,
                        PRICE_CHANGE_FAIL: true,
                        REPLY: true
                    }
                })
                //navigate("/signin")
            }
        });

        return () => {
            unsubscribe();
        };
    }, [])

    if (authData.loading) {
        return <Flex
            align={'center'}
            justify={'center'}
            minH={'100vh'}>
            <Spinner
                thickness='4px'
                speed='1s'
                color='#949FFA'
                size='xl'
            />
        </Flex>
    }

    return (
        <SidebarWithHeader key={username} />

    )
}

export default MainInit