import { useContext, useEffect, useState, useRef } from 'react';
import { Button, Flex, Text, Center, List, Spinner, SkeletonText } from "@chakra-ui/react"
import { GlobalContext, AuthContext } from "../App.js"
import { useNavigate } from 'react-router-dom';
import FollowersItem from "./FollowersItem"
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiLock, FiRefreshCcw } from 'react-icons/fi';
import { Helmet } from "react-helmet"
import { getLocal } from '../util/GetLocal.js';

const Followings = ({ refM }) => {
    const navigate = useNavigate();

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [authData, setAuthData] = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [following, setFollowing] = useState([])
    const [paidFilter, setPaidFilter] = useState(false)

    const [moreFollowing, setMoreFollowing] = useState(true)
    const [moreFollowingLoading, setMoreFollowingLoading] = useState(false)
    const [paidFollowingFilterLoading, setPaidFollowingFilterLoading] = useState(false)

    const next = useRef(0)

    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        const fetchData = async () => {
            setLoading(true)
            let response;
            if (!authData.firebaseuser) {
                toast.error("Redirecting...Please sign in to view followers", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                navigate("/signin")
                return
            }
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/following?paidOnly=${paidFilter}`,
                    {
                        method: "GET",
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to retrieve followers", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setMoreFollowing(false)
                setLoading(false)

            }

            if (!response.ok) {
                const error = await response.json()
                console.log(error)
                toast.error(error.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setMoreFollowing(false)
            }
            else {
                const data = await response.json();
                setFollowing(data.data)
                if (data.data.length > 0) {
                    next.current = data.data[data.data.length - 1].follow_id
                }

                if (data.data.length <= 0) {
                    setMoreFollowing(false)
                }

            }

            setLoading(false)
        }
        fetchData()

        //for when we disable an account when any of these actions are pressed it will keep them as loading which we don't want
        setMoreFollowingLoading(false)
    }, [authData])

    const getNextFollowers = async () => {
        setMoreFollowingLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/following?next=${next.current}&paidOnly=${paidFilter}`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }

                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to fetch followings", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setMoreFollowingLoading(false)
            return
        }

        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            setMoreFollowingLoading(false)
        }
        else {
            const data = await response.json();

            if (data.data.length > 0) {
                next.current = data.data[data.data.length - 1].follow_id
            }

            if (data.data.length <= 0) {
                setMoreFollowing(false)
            }
            setFollowing([...following, ...data.data])
            setMoreFollowingLoading(false)
        }
    }

    const refreshFollowings = async () => {
        setRefreshLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/following?paidOnly=${paidFilter}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to fetch followings", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        else {
            const data = await response.json()

            if (data.data.length > 0) {
                next.current = data.data[data.data.length - 1].follow_id
            }

            if (data.data.length <= 0) {
                setMoreFollowing(false)
            }
            else {
                setMoreFollowing(true)
            }
            setFollowing(data.data)
            setRefreshLoading(false)
        }
    }

    const updatePaidFilter = async () => {
        setPaidFollowingFilterLoading(true)
        let newFilter = !paidFilter
        if (newFilter) {
            toast.info("Displaying only subscriptions...", {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            toast.info("Displaying all followings...", {
                autoClose: 1000,
                hideProgressBar: true
            })
        }

        setPaidFilter(newFilter)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/following?paidOnly=${newFilter}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to refresh feed posts", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setPaidFollowingFilterLoading(false)
            setPaidFilter(!newFilter)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            setPaidFollowingFilterLoading(false)
            setPaidFilter(!newFilter)
            return
        }
        else {
            const data = await response.json()

            if (data.data.length > 0) {
                next.current = data.data[data.data.length - 1].follow_id
            }


            if (data.data.length <= 0) {
                setMoreFollowing(false)
            }
            else {
                setMoreFollowing(true)
            }
            setFollowing(data.data)
            setPaidFollowingFilterLoading(false)


        }
    }

    return (
        <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Following</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/following`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <List width="100%" mx={'auto'} alignItems='center' className="borderfl">
                <Flex direction='row' mb={3} mt={3}>
                    <Button size='sm'  borderRadius={10} color="#949FFA" onClick={refreshFollowings} leftIcon={<FiRefreshCcw size='18' />} mr={2} isDisabled={loading || refreshLoading || paidFollowingFilterLoading} isLoading={refreshLoading}>Refresh</Button>
                    <Button isLoading={paidFollowingFilterLoading} onClick={updatePaidFilter} isDisabled={loading || refreshLoading || paidFollowingFilterLoading} opacity={paidFilter ? 1 : 0.5} size='sm' borderRadius={10} color="red.200" leftIcon={<FiLock size='18' />} mr={2}>Paid</Button>
                </Flex>

                {
                    loading ? (
                        <SkeletonText noOfLines={8} mt={6} spacing={4} />
                    ) : (
                            following.length === 0 ? (
                                <Flex mx={'auto'} bg="#212121" alignItems='center' fontFamily="Futura" justify={'center'} py={5} borderWidth={1} borderRadius={10}>
                                    <Text as='b'>No Followings</Text>
                                </Flex>
                            ) : (
                                    <>
                                        <InfiniteScroll
                                            scrollableTarget={"scrollableDiv"}
                                            dataLength={following.length}
                                            hasMore={false}
                                            loader={<Flex mt={5} mb={5} justify="center"> <Spinner thickness='4px' speed='1s' size='md' color='#949FFA' /> </Flex>}>
                                            {
                                                following.map((item, ind) => {
                                                    return <FollowersItem user={item} key={item.username} type="FOLLOWING" />
                                                })
                                            }
                                        </InfiniteScroll>
                                        {
                                            moreFollowing ? <Center mb={3} mt={2}>
                                                <Button borderRadius={10} isLoading={moreFollowingLoading} size='sm' width="30%" onClick={getNextFollowers}>Load More</Button>
                                            </Center> : null
                                        }
                                    </>
                                )
                        )
                }


            </List>
        </Flex>

    )
}

export default Followings