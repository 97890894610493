import {
    Flex,
    ListItem,
    Spacer,
    Stack,
    Text,
    Icon,
    Box, useToast
} from '@chakra-ui/react';
import { differenceInDays } from "../util/DateUtils"
import { FiFeather, FiTrendingUp, FiUserPlus } from 'react-icons/fi'
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ChatIcon, TriangleUpIcon } from '@chakra-ui/icons';


const NotificationCard = ({ data, isSmallScreen }) => {
    const iconMap = {
        "UPVOTE": { icon: TriangleUpIcon, color: '#949FFA' },
        "POST": { icon: FiFeather, color: 'yellow.200' },
        "FOLLOW": { icon: FiUserPlus, color: 'blue.200' },
        "CLOSE": { icon: FiTrendingUp, color: 'orange.200' },
        "REPLY": { icon: ChatIcon, color: 'cyan.200' }
    }
    const toast = useToast()

   return useMemo(() => {
    return (
        <ListItem bg='#212121' borderWidth={1} borderRadius={10} mb={2}>
            <Flex padding={5} as='b'>
                <Link to={data.url ? data.url : null}>
                    <Icon _hover={{ cursor: 'pointer', opacity: '0.8' }} fontSize='24' as={iconMap[data.type].icon} color={iconMap[data.type].color} mt={3} />
                </Link>

                <Stack ml={5} width="100%" overflowX='auto'>
                    <Flex flexDirection='row'>
                        <Text as='b' overflowX='auto'>{data.message}</Text>
                        {
                            !isSmallScreen ? (<>
                                <Spacer />
                                <Text fontSize='sm' opacity={0.5}>{differenceInDays(data.created_at * 1000)} ago</Text>
                            </>
                            ) : null
                        }

                    </Flex>
                    {
                        data.type === "CLOSED" ? (
                            <Box as='b' noOfLines={3} opacity={0.85} pb={1} fontSize='sm'>
                                {
                                    data.sub_message.split("/").map((d, i) => {
                                        return <Text key={i}>{d}</Text>
                                    })
                                }
                            </Box>
                        ) : (
                                <Box opacity={0.85} fontSize='sm' style={{ whiteSpace: 'pre-wrap' }}>
                                    {data.sub_message}
                                </Box>
                            )
                    }
                    {
                        isSmallScreen ? (<>
                            <Text fontSize='sm' opacity={0.5}>{differenceInDays(data.created_at * 1000)} ago</Text>
                        </>
                        ) : null
                    }

                </Stack>
            </Flex>
        </ListItem>
    )
                }, [JSON.stringify(data)])

}

const areEqual = (p, n) => {
    return p.notification_id === n.notification_id
    //return true
}

//export default NotificationCard
export default NotificationCard