import { useContext, useEffect, useRef, useState } from "react"
import {
    Flex,
    List,
    Text,
    Spinner,
    Box,
    SkeletonText,
    Button,
    useMediaQuery,
    Center
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationCard from "./NotificationCard"
import { FiFeather, FiUserPlus, FiTrendingUp, FiRefreshCcw } from "react-icons/fi";
import { Helmet } from "react-helmet"
import { GlobalContext, AuthContext } from "../App.js"
import { ViewportList } from "react-viewport-list";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ChatIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { getLocal } from "../util/GetLocal";

const Notification = ({ refM }) => {
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [authData, setAuthData] = useContext(AuthContext)

    const [moreData, setMoreData] = useState(true)
    const [loading, setLoading] = useState(true)
    const [moreNotifsLoading, setMoreNotifsLoading] = useState(false)
    const [refreshLoading, setRefreshLoading] = useState(false)

    const listRef = useRef(null)
    const endNotif = useRef(0)

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [initIndex, setInitIndex] = useState(globalData.notifications === null ? -1 : searchParams.get("focus") ? (isNaN(+searchParams.get("focus")) ? -1 : +searchParams.get("focus")) : -1)
    const [offset, setOffSet] = useState(globalData.notifications === null ? 0 : searchParams.get("offset") ? (isNaN(+searchParams.get("offset")) ? 0 : (+searchParams.get("offset")) * 1) : 0)
    if (globalData.notifications === null) {
        window.history.replaceState({}, '', `${location.pathname}`)
    }


    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        const getNotifInfo = async () => {
            setLoading(true)
            let response;
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/notifications?settings=${computeSettingsArray()}`,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to retrieve notifications, please try again", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }
            if (!response.ok) {
                const error = await response.json()
                console.error(error)
                toast.error(error.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
            else {
                const data = await response.json()
                setGlobalData({ ...globalData, notifications: data.notifications })

                if (data.notifications.length > 0) {
                    endNotif.current = data.notifications[data.notifications.length - 1].notification_id
                }

                if (data.notifications.length <= 0) {
                    setMoreData(false)
                }

            }
            setLoading(false)
        }

        if (!authData.firebaseuser) {
            toast.error("Must be signed in to see notifications", {
                autoClose: 2000,
                hideProgressBar: true
            })
            navigate("/signin")
            return
        }

        if (globalData.notifications === null) {
            getNotifInfo()
        }
        else {
            if (globalData.notifications.length > 0) {
                endNotif.current = globalData.notifications[globalData.notifications.length - 1].notification_id
            }
            setMoreData(true)
            setLoading(false)

        }
    }, [authData])


    const getNextNotifications = async () => {
        setMoreNotifsLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/notifications?next=${endNotif.current}&settings=${computeSettingsArray()}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve notifications, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setMoreNotifsLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            const notifs = [...globalData.notifications, ...data.notifications]
            setGlobalData({ ...globalData, notifications: notifs })

            if (data.notifications.length > 0) {
                endNotif.current = data.notifications[data.notifications.length - 1].notification_id
            }

            if (data.notifications.length <= 0) {
                setMoreData(false)
            }

        }
        setMoreNotifsLoading(false)
    }

    const refreshNotifications = async () => {
        setRefreshLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/notifications?settings=${computeSettingsArray()}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve notifications, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            setGlobalData({ ...globalData, notifications: data.notifications })

            if (data.notifications.length > 0) {
                endNotif.current = data.notifications[data.notifications.length - 1].notification_id
            }

            if (data.notifications.length <= 0) {
                setMoreData(false)
            }
            else {
                setMoreData(true)
            }

        }
        setRefreshLoading(false)
    }

    const getNotificationsAfterSettingUpdate = async () => {
        setLoading(true)
        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/notifications?settings=${computeSettingsArray()}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve notifications, please try again", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            setGlobalData({ ...globalData, notifications: data.notifications })

            if (data.notifications.length > 0) {
                endNotif.current = data.notifications[data.notifications.length - 1].notification_id
            }

            if (data.notifications.length <= 0) {
                setMoreData(false)
            }

        }
        setLoading(false)
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)

        }, 10);
    }

    const updateUpVote = () => {
        let tempSettings = globalData.notificationSettings
        if (globalData.notificationSettings.UPVOTE) {
            tempSettings.UPVOTE = false
        }
        else {
            tempSettings.UPVOTE = true
        }
        setGlobalData({ ...globalData, notificationSettings: tempSettings })
        getNotificationsAfterSettingUpdate()
    }

    const updateFollow = () => {
        let tempSettings = globalData.notificationSettings
        if (globalData.notificationSettings.FOLLOW) {
            tempSettings.FOLLOW = false
        }
        else {
            tempSettings.FOLLOW = true
        }
        setGlobalData({ ...globalData, notificationSettings: tempSettings })
        getNotificationsAfterSettingUpdate()
    }

    const updatePost = () => {
        let tempSettings = globalData.notificationSettings
        if (globalData.notificationSettings.POST) {
            tempSettings.POST = false
        }
        else {
            tempSettings.POST = true
        }
        setGlobalData({ ...globalData, notificationSettings: tempSettings })
        getNotificationsAfterSettingUpdate()
    }

    const updateClose = () => {
        let tempSettings = globalData.notificationSettings
        if (globalData.notificationSettings.CLOSE) {
            tempSettings.CLOSE = false
        }
        else {
            tempSettings.CLOSE = true
        }
        setGlobalData({ ...globalData, notificationSettings: tempSettings })
        getNotificationsAfterSettingUpdate()
    }

    const updateReply = () => {
        let tempSettings = globalData.notificationSettings
        if (globalData.notificationSettings.REPLY) {
            tempSettings.REPLY = false
        }
        else {
            tempSettings.REPLY = true
        }
        setGlobalData({ ...globalData, notificationSettings: tempSettings })
        getNotificationsAfterSettingUpdate()
    }

    const computeSettingsArray = () => {
        let arr = []
        for (const key in globalData.notificationSettings) {
            if (globalData.notificationSettings[key] === true) {
                arr.push(key);
            }
        }
        return arr
    }

    return <Flex direction='column' mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" >
        <Helmet>
            <meta charSet="utf-8" />
            <title>Bloomtrades | Notifications</title>
            <link rel="canonical" href="https://www.bloomtrades.net/notifications" />
            <meta name="description" content="Access free market predictions and turn your insights into earnings" />
        </Helmet>
        <List width="100%" mx={'auto'} alignItems='center' className="borderst">
            <Box overflowX="auto" width="100%" style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}>
                <Flex direction="row" mt={3} mb={3}>
                    <Flex whiteSpace="nowrap">
                        <Button size='sm' borderRadius={10} color="#949FFA" onClick={refreshNotifications} leftIcon={<FiRefreshCcw size='18' />} mr={2} isDisabled={loading || moreNotifsLoading} isLoading={refreshLoading}>Refresh</Button>
                        <Button size='sm' borderRadius={10} color="blue.200" onClick={updateFollow} leftIcon={<FiUserPlus size='18' />} mr={2} isDisabled={loading || moreNotifsLoading || refreshLoading} opacity={globalData.notificationSettings.FOLLOW ? 1 : 0.5}>Subscription</Button>
                        <Button size='sm' borderRadius={10} color="orange.200" onClick={updateClose} leftIcon={<FiTrendingUp size='18' />} mr={2} isDisabled={loading || moreNotifsLoading || refreshLoading} opacity={globalData.notificationSettings.CLOSE ? 1 : 0.5}>Prediction</Button>
                        <Button size='sm' borderRadius={10} color="yellow.200" onClick={updatePost} leftIcon={<FiFeather size='18' />} mr={2} isDisabled={loading || moreNotifsLoading || refreshLoading} opacity={globalData.notificationSettings.POST ? 1 : 0.5}>Post</Button>
                        <Button size='sm' borderRadius={10} color="cyan.200" onClick={updateReply} leftIcon={<ChatIcon size='18' />} mr={2} isDisabled={loading || moreNotifsLoading || refreshLoading} opacity={globalData.notificationSettings.REPLY ? 1 : 0.5}>Reply</Button>
                        <Button size='sm' borderRadius={10} color="#949FFA" onClick={updateUpVote} leftIcon={<TriangleUpIcon size='18' />} mr={2} isDisabled={loading || moreNotifsLoading || refreshLoading} opacity={globalData.notificationSettings.UPVOTE ? 1 : 0.5}>Upvote</Button>
                    </Flex>
                </Flex>
            </Box>
            {
                loading ? (
                    <Box mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" mb={20} mt={3}>
                        <SkeletonText noOfLines={8} mt={6} spacing={4} />
                    </Box>
                ) : (
                        !globalData.notifications || globalData.notifications.length === 0 ? (
                            <Flex bg='#212121' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10}>
                                <Text as='b'>No notifications</Text>
                            </Flex>
                        )
                            : (
                                <>
                                    <InfiniteScroll
                                        scrollableTarget={"scrollableDiv"}
                                        dataLength={globalData.notifications.length}
                                        onScroll={(e) => {
                                            window.history.replaceState({}, '', `${location.pathname}?focus=${listRef.current.getScrollPosition().index}&offset=${listRef.current.getScrollPosition().offset}`)
                                        }}
                                        hasMore={false}
                                        loader={<Flex mt={5} mb={5} justify="center"> <Spinner thickness='4px' speed='1s' size='md' color='#949FFA' /> </Flex>}>
                                        {
                                            <ViewportList ref={listRef} viewportRef={refM} items={globalData.notifications || []} initialIndex={initIndex} initialOffset={offset} initialPrerender={10}>
                                                {(item, index) => (
                                                    <NotificationCard data={item} isSmallScreen={isSmallScreen} key={item.notification_id} />
                                                )}
                                            </ViewportList>
                                        }
                                    </InfiniteScroll>
                                    {
                                        moreData ? <Center mb={3} mt={2}>
                                            <Button borderRadius={10} isLoading={moreNotifsLoading} size='sm' width="30%" onClick={getNextNotifications}>Load More</Button>
                                        </Center> : null
                                    }
                                </>

                            )
                    )
            }
        </List>
    </Flex>

}

export default Notification