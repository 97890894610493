import { useContext, useEffect, useState } from 'react';

import {
    Button, Flex, Modal, ModalBody, Textarea, Text, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Image, Divider, Center, Spacer, Input, CloseButton, SimpleGrid, IconButton, Box
} from "@chakra-ui/react"
import { GlobalContext, AuthContext } from "../App.js"
import ResizeTextarea from "react-textarea-autosize";
import { FiImage, FiLock, FiUnlock, FiTrendingUp, FiTrendingDown, FiPlusCircle } from 'react-icons/fi';
import ImageUploading from "react-images-uploading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendEmailVerification } from 'firebase/auth';
import { getLocal } from '../util/GetLocal.js';
import { BsDiscord } from "react-icons/bs";
import { CloseIcon } from '@chakra-ui/icons';

const initsymbols = ['AAPL', 'NVDA', 'AMZN', 'GOOGL', 'META', 'MSFT', 'NFLX', 'AMD', 'TSLA', 'UBER', 'SHOP', 'V', 'COIN', 'HOOD', 'PLTR', 'DIS', 'SPY', 'IWM'];

const CreatePost = ({ isSmallScreen, isOpen, setIsOpen }) => {

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [authData, setAuthData] = useContext(AuthContext)

    const [postText, setPostText] = useState("")
    const [isFree, setIsFree] = useState(true)
    const [isTrack, setIsTrack] = useState(false)
    const [isBearish, setIsBearish] = useState(false)
    const [symbol, setSymbol] = useState("")
    const [postLoading, setPostLoading] = useState(false)
    const [checkPriceLoading, setCheckPriceLoading] = useState(false)
    const [symbolVerifyData, setSymbolVerifyData] = useState(null)
    const [newImagePost, setNewImagePost] = useState([])
    const [discordPost, setDiscordPost] = useState(false)

    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [symbols, setSymbols] = useState([]);

    useEffect(() => {
        try {
            const savedSymbols = localStorage.getItem('savedSymbols');
            // Attempt to parse the saved symbols
            const parsedSymbols = JSON.parse(savedSymbols);

            // Validate that parsedSymbols is an array
            if (Array.isArray(parsedSymbols)) {
                setSymbols(parsedSymbols);
            } else {
                throw new Error('Parsed data is not an array');
            }
        } catch (error) {
            console.error('Error loading symbols from local storage:', error);
            // Fallback to initial symbols and update local storage
            localStorage.setItem('savedSymbols', JSON.stringify(initsymbols));
            setSymbols(initsymbols);
        }
    }, []);

    // Function to handle button click
    const handleSelect = (symbol) => {
        // If the clicked symbol is already selected, deselect it
        if (selectedSymbol === symbol) {
            setSelectedSymbol(null);
            setSymbolVerifyData(null);
        } else {
            setSelectedSymbol(symbol);
            checkPrice(symbol, false);
            setSymbol("")
        }
    };

    const handleDelete = (symbol) => {
        const updatedSymbols = symbols.filter((s) => s !== symbol);
        setSymbols(updatedSymbols);
        // Update local storage immediately after modifying symbols
        localStorage.setItem('savedSymbols', JSON.stringify(updatedSymbols));
        if (selectedSymbol === symbol) {
            setSelectedSymbol(null); // Deselect if the deleted symbol was selected
            setSymbolVerifyData(null);
        }
    };

    // Function to handle add button click
    const handleAdd = () => {
        const trimmedSymbol = symbol.trim().toUpperCase();
        if (trimmedSymbol && !symbols.includes(trimmedSymbol) && trimmedSymbol.length <= 7) {
            const updatedSymbols = [...symbols, trimmedSymbol];
            setSymbols(updatedSymbols);
            // Update local storage immediately after adding new symbol
            localStorage.setItem('savedSymbols', JSON.stringify(updatedSymbols));
        }
        else {
            toast.error("Invalid symbol or already exists", {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    };

    const onChangePostImage = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setNewImagePost(imageList);
    };

    const timeObj = {
        timeZone: "America/New_York",
        hour: '2-digit',
        minute: '2-digit',
        month: 'short',
        timeZoneName: 'short',
        year: 'numeric',
        day: 'numeric'
    }

    const freeChange = () => {
        let message = ""
        if (!isFree) {
            message = "Anyone will be able to view this post"
        }
        else {
            message = "Only subscribers will be able to view this post"
        }
        setIsFree(!isFree)
        toast.info(message, {
            autoClose: 2000,
            hideProgressBar: true
        })
    }

    const discordPostChange = () => {
        if (!discordPost) {
            let message = "Bloomtrades bot will create and track this prediction in your specified Discord Channel"
            toast.info(message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        setDiscordPost(!discordPost)
    }

    const trackChange = () => {
        let message = ""
        if (!isTrack) {
            message = "Adding prediction to post based on stock & sentiment"
        }
        else {
            message = "Removing prediction from post"
        }
        setIsTrack(!isTrack)
        toast.info(message, {
            autoClose: 2000,
            hideProgressBar: true
        })
    }

    const createPost = async () => {
        setPostLoading(true)
        if (postText.trim().length <= 0 && ((symbol.trim().length <= 0 && !selectedSymbol) || !symbolVerifyData)) {
            toast.error("Cannot post empty content without a prediction", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setPostLoading(false)
            return
        }
        else if (postText.length > 280) {
            toast.error("Post must have less than 280 characters", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setPostLoading(false)
            return
        }
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/post`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser, true)
                    },
                    body: JSON.stringify({
                        content: postText.replaceAll("\n", "\n"),
                        isFree: isFree,
                        isTrack: symbolVerifyData ? true : false,
                        symbol: symbol || selectedSymbol,
                        sentiment: symbolVerifyData ? (isBearish ? "Bearish" : "Bullish") : null,
                        file: newImagePost[0] ? newImagePost[0].data_url : null,
                        type: newImagePost[0] ? newImagePost[0].file.type : null,
                        postToDiscord: symbolVerifyData ? discordPost : false
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to create post", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setPostLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setPostLoading(false)
                return
            }
            const e = await response.json()
            console.log(e)
            if (e.state === "VERIFY") {
                toast.error(e.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                sendEmailVerification(authData.firebaseuser).then(() => {
                    toast.info("Sending email verification...", {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }).catch((error) => {
                    const errorCode = error.code;
                    console.error(error)
                    toast.error(`Failed to send email verification`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                });
            }
            else {
                toast.error(e.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
        }
        else {
            let data = await response.json()
            data.post = {
                ...data.post,
                username: authData.firebaseuser.displayName,
                avatar: authData.firebaseuser.photoURL
            }
            let allPosts = globalData.allPosts
            if (!allPosts[authData.firebaseuser.displayName]) {
                allPosts[authData.firebaseuser.displayName] = []
            }
            allPosts[authData.firebaseuser.displayName] = [data.post, ...allPosts[authData.firebaseuser.displayName]]
            setGlobalData({ ...globalData, allPosts: allPosts })

            toast.success(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })

            setIsOpen(false)
            setSymbol("")
            setPostText("")
            setIsFree(true)
            setIsTrack(false)
            setIsBearish(false)
            setNewImagePost([])
            setSymbolVerifyData(null)
            setSelectedSymbol(null)
        }
        setPostLoading(false)
    }

    const checkPrice = async (symbolSelected, isCustom) => {
        setCheckPriceLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/checkprice?symbol=${symbolSelected}`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to verify stock symbol", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setSymbolVerifyData(null)
            setCheckPriceLoading(false)
            return
        }
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setSymbolVerifyData(null)
                setCheckPriceLoading(false)
                setSelectedSymbol(null)
                return
            }
            const e = await response.json()
            console.log(e)
            toast.error(e.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            setSymbolVerifyData(null)
            setCheckPriceLoading(false)
            setSelectedSymbol(null)
        }
        else {
            let data = await response.json()
            console.log(data)
            setSymbolVerifyData(data.data)
            setCheckPriceLoading(false)
            if (isCustom) {
                setSelectedSymbol(null)
            }
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={isSmallScreen ? 'full' : 'xl'} autoFocus={false}>
            <ModalOverlay />
            <ModalContent bg='#1C1C1C' boxShadow='lg'>
                <ModalHeader fontFamily="Futura">Create Post</ModalHeader>
                <ModalBody fontFamily="Futura" >
                    <>
                        <Divider mb={4} />
                        <Flex alignItems="center" width="100%" mt={1} mb={4} fontFamily="Futura">
                            <Textarea
                                value={postText}
                                onChange={e => setPostText(e.target.value)}
                                placeholder="Share your ideas! (optional)"
                                fontWeight='bold'
                                minH="4rem"
                                as={ResizeTextarea}
                                focusBorderColor="#949FFA"
                                bg='#212121'
                            />
                        </Flex>

                        <Flex flexDirection='row' width='100%' mb={3}>
                            <ImageUploading
                                value={newImagePost}
                                onChange={onChangePostImage}
                                dataURLKey="data_url"
                                acceptType={["jpeg", "jpg", "png"]}
                                maxFileSize={1000000}
                                onError={(e) => {
                                    let message = "Error in uploading image"
                                    if (e.maxFileSize) {
                                        message = "Error in uploading image, file size exceeds limit of 1MB"
                                    }
                                    else if (e.acceptType) {
                                        message = "Error in uploading image, file type not supported. Supported types include jpeg, jpg & png"
                                    }
                                    toast.error(message, {
                                        autoClose: 3000,
                                        hideProgressBar: true
                                    })
                                }}
                            >
                                {({
                                    imageList,
                                    errors,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    <Button size='sm' borderRadius={10} onClick={onImageUpload} color='blue.200' leftIcon={<FiImage size='18' />} mr={2}>
                                        <Text as='b'>Image</Text>
                                    </Button>
                                )}
                            </ImageUploading>
                            <Button size='sm' borderRadius={10} onClick={freeChange} color={isFree ? 'green.200' : 'red.200'} leftIcon={isFree ? <FiUnlock size='18' /> : <FiLock size='18' />} mr={2}>
                                <Text as='b'>{isFree ? 'Free' : 'Paid'}</Text>
                            </Button>

                            {
                                symbolVerifyData ? (
                                    <Button size='sm' borderRadius={10} onClick={discordPostChange} color={discordPost ? '#949FFA' : 'red.200'} leftIcon={<BsDiscord size='18' />} mr={2}>
                                        <Text as='b' textDecoration={discordPost ? 'unset' : 'line-through'}>Discord</Text>
                                    </Button>
                                ) : null
                            }

                            <Spacer />
                            <Flex margin='auto'>
                                <Text fontFamily="Futura" fontSize='sm' color={postText.length > 280 ? 'red.200' : 'green.200'} as='b'>{postText.length}/280</Text>
                            </Flex>

                        </Flex>

                        <SimpleGrid columns={3} spacing={4}>
                            {symbols.map((symbol) => (
                                <Box key={symbol} position="relative">
                                    <Button
                                        onClick={() => handleSelect(symbol)}
                                        borderWidth={1}
                                        borderRadius={10}
                                        border={selectedSymbol === symbol ? '2px solid #949FFA' : null}
                                        bg='#212121'
                                        _hover={{ cursor: "pointer" }}
                                        w="full"
                                        overflow="hidden"
                                    >
                                        {symbol}
                                    </Button>
                                    <IconButton
                                        icon={<CloseIcon />}
                                        size="xs"
                                        colorScheme="red"
                                        position="absolute"
                                        top="-5px"
                                        right="-5px"
                                        onClick={() => handleDelete(symbol)}
                                        aria-label={`Delete ${symbol}`}
                                    />
                                </Box>
                            ))}
                        </SimpleGrid>

                        <Flex direction="row" spacing="2" mt={3}>
                            <Input focusBorderColor="#949FFA" bg='#212121' fontFamily="Futura" borderRadius={10} size='md' fontSize='16px' placeholder='Symbol' fontWeight='bold' maxW={168} value={symbol} onChange={e => setSymbol(e.target.value.toUpperCase())} />
                            <Button color='#949FFA' variant='ghost' isLoading={checkPriceLoading} size='md' onClick={() => checkPrice(symbol, true)} borderRadius={10} ml={2}>
                                <Text as='b'>Verify</Text>
                            </Button>
                            <Button
                                onClick={handleAdd}
                                size="md"
                                color='green.200'                                
                                variant='ghost'
                                leftIcon={<FiPlusCircle size='18' />} 
                                borderRadius={10}
                            >
                                Save
                            </Button>
                        </Flex>

                        {
                            symbolVerifyData ? (
                                <Flex mt={3} padding={3} direction="column" spacing="2" fontFamily="Futura" bg='#212121' _hover={{ cursor: "pointer" }} borderWidth={1} borderRadius={10}>
                                    <Text as='b'>{symbolVerifyData.name}</Text>
                                    <Text as='b'>${symbolVerifyData.price} · {new Date(symbolVerifyData.time * 1000).toLocaleString('en-US', { ...timeObj })}</Text>
                                </Flex>
                            ) : null
                        }

                        {
                            symbolVerifyData ? (
                                <>
                                    <Flex mt={3} direction='column'>
                                        <Button rightIcon={<FiTrendingUp size='18' />} size='sm' fontFamily="Futura" isDisabled={postLoading} onClick={() => setIsBearish(false)} borderRadius={10} color={isBearish ? '' : "#1C1C1C"} bg={isBearish ? '' : 'green.200'} opacity={isBearish ? 0.85 : 1}
                                            _hover={{ bg: isBearish ? '#323232' : "", opacity: isBearish ? 0.85 : 1 }}>
                                            Stock will increase
                                        </Button>
                                        <Button rightIcon={<FiTrendingDown size='18' />} mt={2} mb={3} size='sm' fontFamily="Futura" isDisabled={postLoading} onClick={() => setIsBearish(true)} borderRadius={10} color={isBearish ? '#1C1C1C' : ""} bg={isBearish ? 'red.200' : ''} opacity={isBearish ? 1 : 0.85}
                                            _hover={{ bg: isBearish ? '' : "#323232", opacity: isBearish ? 1 : 0.85 }}>
                                            Stock will decrease
                                        </Button>
                                    </Flex>
                                </>
                            ) : null
                        }

                        {
                            newImagePost && newImagePost[0] ? (
                                <Flex pos='relative' pb={3} pt={3}>
                                    <Image src={newImagePost[0].data_url} borderRadius={5} />
                                    <CloseButton
                                        onClick={() => setNewImagePost([])}
                                        size="sm"
                                        m={3}
                                        position="absolute"
                                        top="0"
                                        right="0"
                                        color='#EAEAEA'
                                        borderRadius={10}
                                    />


                                </Flex>) : null

                        }

                    </>
                </ModalBody>
                <ModalFooter fontFamily="Futura">
                    <Button borderRadius={10} isLoading={postLoading || checkPriceLoading} variant='ghost' color='#949FFA' onClick={createPost}>Post</Button>
                    <Center height='25px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <Button borderRadius={10} isDisabled={postLoading} bg="" color='red.200' onClick={() => setIsOpen(false)}>Exit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}

export default CreatePost