import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    Spinner,
    useMediaQuery
} from '@chakra-ui/react'; import { useNavigate, useSearchParams } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { AuthContext } from "./App.js"
import { auth } from './config/firebaseClient';
import { verifyPasswordResetCode, confirmPasswordReset, signOut, applyActionCode, checkActionCode } from "firebase/auth";
import { useState, useEffect, useContext } from 'react';
import { Helmet } from "react-helmet"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccountAction = () => {
    const [loading, setLoading] = useState(true)
    const [changePasswordLoading, setChangePasswordLoading] = useState(false)
    const [verifyEmailLoading, setVerifyEmailLoading] = useState(false)

    const [recoverEmailLoading, setRecoverEmailLoading] = useState(false)

    const [password, setPassword] = useState("")
    const [confPassword, setConfPassword] = useState("")
    const [emailForPasswordReset, setEmailForPasswordReset] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [showConfPassword, setShowConfPassword] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();
    const [authData, setAuthData] = useContext(AuthContext)

    const actionCode = searchParams.get("oobCode")
    const [mode, setMode] = useState("")
    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");


    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        switch (searchParams.get("mode")) {
            case 'resetPassword':
                // Display reset password handler and UI.
                console.log("resetPassword")
                verifyPasswordResetCode(auth, actionCode).then((email) => {
                    setEmailForPasswordReset(email)
                    setMode("resetPassword")
                }).catch((error) => {
                    console.error(error)
                    toast.error(`${error.message.replaceAll("Firebase: ", "")} Please try resetting password again`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    // Invalid or expired action code. Ask user to try to reset the password
                    // again.
                });
                break;
            case 'recoverEmail': //currently nothing so far for this
                // Display email recovery handler and UI.
                //handleRecoverEmail(auth, actionCode, lang);
                console.log("recoverEmail")
                setMode("recoverEmail")
                break;
            case 'verifyEmail':
                // Display email verification handler and UI.
                //handleVerifyEmail(auth, actionCode, continueUrl, lang);
                console.log("verifyEmail")
                setMode("verifyEmail")
                break;
            default:
                console.log("invalid mode")
                setMode("Invalid")
                break;
            // Error: invalid mode.
        }
        setLoading(false)

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // Remove event listener on component unmount
            document.removeEventListener("keydown", handleKeyDown);
        };

    }, [])

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            document.getElementById("resetPassword").click();
        }
    }

    const handleVerifyEmail = () => {
        setVerifyEmailLoading(true)

        applyActionCode(auth, actionCode).then((resp) => {
            // Email address has been verified.

            // TODO: Display a confirmation message to the user.
            // You could also provide the user with a link back to the app.

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            if (authData.firebaseuser) {
                toast.success(`Email has been verified, redirecting to feed...`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                navigate("/feed")
                setVerifyEmailLoading(false)
            }
            else {
                toast.success(`Email has been verified, redirecting to sign in page...`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                navigate("/signin")
                setVerifyEmailLoading(false)
            }

        }).catch((error) => {
            // Code is invalid or expired. Ask the user to verify their email address
            // again.
            console.error(error)
            console.log("expired action code")
            toast.error(`${error.message.replaceAll("Firebase: ", "")}  Please try verifying email again`, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setVerifyEmailLoading(false)
        });
    }

    const handleResetPassword = () => {
        setChangePasswordLoading(true)
        if (password !== confPassword) {
            toast.error('Passwords do not match.', {
                autoClose: 2000,
                hideProgressBar: true
            })
            setChangePasswordLoading(false)
            return
        }
        confirmPasswordReset(auth, actionCode, password).then((resp) => {
            // Password reset has been confirmed and new password updated.

            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            if (authData.firebaseuser) {
                signOut(auth).then(() => {
                    toast.success(`Password successfully updated, redirecting to sign in page...`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    setChangePasswordLoading(false)
                    navigate("/signin")
                }).catch((error) => {
                    console.error(error)
                    toast.success(`Password successfully updated, redirecting to sign in page...`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    setChangePasswordLoading(false)
                    navigate("/signin")
                });

            }
            else {
                toast.success(`Password successfully updated, redirecting to sign in page...`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setChangePasswordLoading(false)
                navigate("/signin")
            }
        }).catch((error) => {
            console.error(error)
            toast.error(`${error.message.replaceAll("Firebase: ", "")}`, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setChangePasswordLoading(false)
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
        });
    }

    const handleRecoverEmail = () => {
        setRecoverEmailLoading(true)
        let restoredEmail = null;
        // Confirm the action code is valid.
        checkActionCode(auth, actionCode).then((info) => {
            // Get the restored email address.
            restoredEmail = info['data']['email'];

            // Revert to the old email.
            return applyActionCode(auth, actionCode);
        }).then(() => {
            // Account email reverted to restoredEmail
            // TODO: Display a confirmation message to the user.
            toast.success(`Email restored to '${restoredEmail}'`, {
                autoClose: 2000,
                hideProgressBar: true
            })

            if (authData.firebaseuser) {
                toast.success(`Email restored to '${restoredEmail}', redirecting to feed...`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                navigate("/feed")
                setRecoverEmailLoading(false)
            }
            else {
                toast.success(`Email restored to '${restoredEmail}', redirecting to sign in page...`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                navigate("/signin")
                setRecoverEmailLoading(false)
            }
            

        }).catch((error) => {
            console.error(error)
            toast.error(`${error.message.replaceAll("Firebase: ", "")}`, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRecoverEmailLoading(false)
        });
    }

    if (loading) {
        return <Flex
            align={'center'}
            justify={'center'}
            minH={'100vh'}>
            <Spinner
                thickness='4px'
                speed='1s'
                color='#949FFA'
                size='xl'
            />
        </Flex>
    }

    if (mode === "" || mode === "Invalid") {
        return (
            <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" direction='column'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Invalid Action</title>
                    <link rel="canonical" href="https://www.bloomtrades.net/accountaction/accountaction" />
                    <meta name="description" content="Access free market predictions and turn your insights into earning" />
                </Helmet>
                <Heading size="md" mb={2} mt={5} fontFamily="Futura">
                    Invalid Account Action
            </Heading>
                <Text mb={4} opacity={0.85}>Action now has either expired or is invalid</Text>

                <Button size='sm' borderRadius={10} width={isSmallScreen ? "100%" : "75%"} onClick={() => navigate(authData.firebaseuser ? "/feed" : "/")} bg='#949ffa' color="#1C1C1C" _hover={{color: '#1C1C1C', bg: '#949ffa'}}>
                    Go to bloomtrades.net
                </Button>
            </Flex>
        )
    }

    if (mode === "resetPassword") {
        return (
            <Flex fontFamily="Futura">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Reset Password</title>
                    <link rel="canonical" href="https://www.bloomtrades.net/accountaction" />
                    <meta name="description" content="Access free market predictions and turn your insights into earning" />
                </Helmet>
                <Stack mx={'auto'} maxW={'lg'} px={3} width="100%" mt={6}>
                    <Stack>
                        <Heading fontFamily="Futura" fontSize={'xl'}>
                            Password Reset for '{emailForPasswordReset}'
                        </Heading>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg='#282828'
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={2}>


                            <FormControl isDisabled={loading} id="password">
                                <FormLabel fontWeight='bold' fontFamily="Futura">New Password</FormLabel>
                                <InputGroup>
                                    <Input focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' value={password} type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                                    <InputRightElement h={'full'}>
                                        <Button
                                            borderRadius={10}
                                            variant={'ghost'}
                                            onClick={() =>
                                                setShowPassword((showPassword) => !showPassword)
                                            }>
                                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl isDisabled={loading} id="confpassword">
                                <FormLabel fontWeight='bold' fontFamily="Futura">Confirm New Password</FormLabel>
                                <InputGroup>
                                    <Input focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" value={confPassword} type={showConfPassword ? 'text' : 'password'} onChange={(e) => setConfPassword(e.target.value)} />
                                    <InputRightElement h={'full'}>
                                        <Button
                                            borderRadius={10}
                                            variant={'ghost'}
                                            onClick={() =>
                                                setShowConfPassword((showConfPassword) => !showConfPassword)
                                            }>
                                            {showConfPassword ? <ViewIcon /> : <ViewOffIcon />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Stack spacing={10} pt={2}>
                                <Button
                                    id="resetPassword"
                                    borderRadius={10}
                                    isLoading={changePasswordLoading}
                                    onClick={handleResetPassword}
                                    size="sm"
                                    bg='#949ffa'
                                    color="#1C1C1C"
                                    _hover={{color: '#1C1C1C', bg: '#949ffa'}}
                                >
                                    Reset Password
                                </Button>
                            </Stack>

                        </Stack>
                    </Box>
                </Stack>
            </Flex>
        )
    }

    if (mode === "verifyEmail") {
        return (
            <Flex fontFamily="Futura">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Verify Email</title>
                    <link rel="canonical" href="https://www.bloomtrades.net/accountaction" />
                    <meta name="description" content="Access free market predictions and turn your insights into earning" />
                </Helmet>
                <Stack mx={'auto'} maxW={'lg'} px={3} width="100%" mt={6}>

                    <Button
                        id="verifyEmail"
                        borderRadius={10}
                        isLoading={verifyEmailLoading}
                        onClick={handleVerifyEmail}
                        size="sm"
                        bg='#949ffa'
                        color="#1C1C1C"
                        _hover={{color: '#1C1C1C', bg: '#949ffa'}}
                    >
                        Verify Email
                </Button>
                </Stack>
            </Flex>
        )
    }

    if (mode === "recoverEmail") {
        return (
            <Flex fontFamily="Futura">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bloomtrades | Recover Email</title>
                    <link rel="canonical" href="https://www.bloomtrades.net/accountaction" />
                    <meta name="description" content="Access free market predictions and turn your insights into earning" />
                </Helmet>
                <Stack mx={'auto'} maxW={'lg'} px={3} width="100%" mt={6}>

                    <Button
                        id="recoverEmail"
                        borderRadius={10}
                        isLoading={recoverEmailLoading}
                        onClick={handleRecoverEmail}
                        size="sm"
                        bg='#949ffa'
                        color="#1C1C1C"
                        _hover={{color: '#1C1C1C', bg: '#949ffa'}}
                    >
                        Recover Email
                </Button>
                </Stack>
            </Flex>
        )
    }


}

export default AccountAction