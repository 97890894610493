import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Spinner,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useToast,
    HStack
} from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../App.js"
import { auth } from '../config/firebaseClient';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signin = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [initLoading, setInitLoading] = useState(true)
    const navigate = useNavigate();


    const [authData, setAuthData] = useContext(AuthContext)

    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }

    useEffect(() => {
        console.log(authData)
        if (authData.firebaseuser) {
            navigate("/feed")
            return
        }
        setInitLoading(false)

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // Remove event listener on component unmount
            document.removeEventListener("keydown", handleKeyDown);
        };

    }, [authData])


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            document.getElementById("signInButton").click();
        }
    }

    const signIn = async () => {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user)
            })
            .catch((error) => {
                const errorCode = error.code;

                toast.error(`${error.message.replaceAll("Firebase: ", "")}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
            });
    }

    const sendPasswordReset = async () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                toast.success(`Instructions to reset password sent to ${email}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                toast.error(`${error.message.replaceAll("Firebase: ", "")}`, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
            });
    }

    if (initLoading) {
        return <Flex
            align={'center'}
            justify={'center'}
            minH={'100vh'}>
            <Spinner
                thickness='4px'
                speed='1s'
                color='#949FFA'
                size='xl'
            />
        </Flex>
    }

    return (
        <Flex fontFamily="Futura">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Sign in</title>
                <link rel="canonical" href="https://www.bloomtrades.net/signin" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Stack spacing={6} mx={'auto'} maxW={'lg'} py={6} px={3} width="100%">
                <Stack align={'center'}>
                    <Heading fontFamily="Futura" fontSize={'4xl'} textAlign={'center'}>
                        Sign in
                    </Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg='#282828'
                    boxShadow='xl'
                    p={8}>
                    <Stack spacing={2}>
                        <Box>
                            <FormLabel fontWeight='bold' fontFamily="Futura">Email address</FormLabel>
                            <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' fontFamily="Futura" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Box>
                        <Box>
                            <FormLabel fontWeight='bold' fontFamily="Futura">Password</FormLabel>
                            <InputGroup>
                                <Input isDisabled={loading} focusBorderColor="#949FFA" bg='#212121' borderRadius={10} fontWeight='bold' value={password} type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                                <InputRightElement h={'full'}>
                                    <Button
                                        borderRadius={10}
                                        variant={'ghost'}
                                        onClick={() =>
                                            setShowPassword((showPassword) => !showPassword)
                                        }>
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </Box>

                        <Stack spacing={10} pt={2}>
                            <Button
                                id="signInButton"
                                borderRadius={10}
                                disabled={loading}
                                isLoading={loading}
                                onClick={signIn}
                                loadingText="Signing in..."
                                size="md"
                                bg='#949ffa'
                                color="#1C1C1C"
                                _hover={{ color: '#1C1C1C', bg: '#949ffa' }}
                            >
                                Sign in
                            </Button>
                        </Stack>
                        <HStack pt={2} spacing={1}>
                            <Text fontWeight='bold' align={'center'}>Don't have an account?</Text>
                            <Link to='/signup'><Text fontWeight='bold' color={'#949FFA'}>Sign up</Text> </Link>
                        </HStack>
                        <HStack pt={0} spacing={1}>
                            <Text onClick={sendPasswordReset} fontWeight='bold' color={'#949FFA'} _hover={{ cursor: "pointer" }}>Send password reset email</Text>
                        </HStack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}

export default Signin