import {
    Flex,
    Text,
    Spacer,
    Box,
    Tag,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon
} from '@chakra-ui/react';
import { useContext } from 'react';
import { convertSeconds } from '../util/DateUtils';
import './profileMain.css'
import { GlobalContext } from "../App.js"

const UserStats = ({ username }) => {
    const [globalData, setGlobalData] = useContext(GlobalContext)

    return (
        <Flex direction='column' width="100%">
            <Accordion allowToggle borderBottomColor='#212121' borderTopColor='#212121'>
                <AccordionItem>

                    <AccordionButton _hover={{bg: '#212121'}} opacity={0.85}>
                        <Box as="b" flex='1' textAlign='left'>
                            View All Stats
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel pb={4}>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Open Predictions</Text>
                            <Spacer />
                            <Tag colorScheme='teal' fontWeight='700'>{globalData.userInfo[username]?.stats.total_open_posts}</Tag>
                        </Flex>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Top Symbol</Text>
                            <Spacer />
                            <Tag colorScheme='blue' fontWeight='700'>{globalData.userInfo[username]?.stats.top_symbol || "N/A"}</Tag>
                        </Flex>


                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Losses</Text>
                            <Spacer />
                            <Tag colorScheme='red' fontWeight='700'>{globalData.userInfo[username]?.stats.total_loss_posts.replaceAll(".00", "")}%</Tag>
                        </Flex>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Neutral</Text>
                            <Spacer />
                            <Tag colorScheme='gray' fontWeight='700'>{globalData.userInfo[username]?.stats.total_neutral_posts.replaceAll(".00", "")}%</Tag>
                        </Flex>

                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Free Predictions</Text>
                            <Spacer />
                            <Tag colorScheme='green' fontWeight='700'>{globalData.userInfo[username]?.stats.total_free_posts}</Tag>
                        </Flex>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Paid Predictions</Text>
                            <Spacer />
                            <Tag colorScheme='red' fontWeight='700'>{globalData.userInfo[username]?.stats.total_not_free_posts}</Tag>
                        </Flex>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Avg. Prediction Time</Text>
                            <Spacer />
                            <Tag colorScheme='gray' fontWeight='700'>{convertSeconds(globalData.userInfo[username]?.stats.avg_time_difference, true)}</Tag>
                        </Flex>

                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Top Bullish Prediction</Text>
                            <Spacer />
                            <Tag colorScheme='green' fontWeight='700'>{globalData.userInfo[username]?.stats.largest_bullish_closed_percent ? `${globalData.userInfo[username]?.stats.largest_bullish_closed_percent.replaceAll(".00", "")}%` : "N/A"}</Tag>
                        </Flex>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Top Bearish Prediction</Text>
                            <Spacer />
                            <Tag colorScheme='red' fontWeight='700'>{globalData.userInfo[username]?.stats.smallest_bearish_closed_percent ? `${globalData.userInfo[username]?.stats.smallest_bearish_closed_percent.replaceAll(".00", "")}%` : "N/A"}</Tag>
                        </Flex>

                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Avg. Bullish Prediction</Text>
                            <Spacer />
                            <Tag colorScheme='green' fontWeight='700'>{globalData.userInfo[username]?.stats.average_bullish_closed_percent ? `${globalData.userInfo[username]?.stats.average_bullish_closed_percent.replaceAll(".00", "")}%` : "N/A"}</Tag>
                        </Flex>
                        <Flex direction='row' fontFamily="Futura" mt={2}>
                            <Text as='b'>Avg. Bearish Prediction</Text>
                            <Spacer />
                            <Tag colorScheme='red' fontWeight='700'>{globalData.userInfo[username]?.stats.average_bearish_closed_percent ? `${globalData.userInfo[username]?.stats.average_bearish_closed_percent.replaceAll(".00", "")}%` : "N/A"}</Tag>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Flex>
    )
}

export default UserStats