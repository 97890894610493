export function formatLargeNumber(number) {
    if (number < 1e3) {
        return number
      } 
      else if (number < 1e6) {
        const rounded = Math.floor(number / 10) / 100;
        return rounded.toFixed(2) + 'K';
      } else {
        const rounded = Math.floor(number / 1e6 * 100) / 100;
        return rounded.toFixed(2) + 'M';
      }
}
