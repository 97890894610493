import { useContext, useEffect, useState } from 'react';
import { Button, Flex, Text, RadioGroup, List, Stack, SkeletonText, useMediaQuery, Avatar, ListItem, Tag, Spacer, Icon, HStack } from "@chakra-ui/react"
import { AuthContext } from "../App.js"
import { Helmet } from "react-helmet"
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatLargeNumber } from '../util/NumberUtils';
import { getLocal } from '../util/GetLocal.js';
import { MdVerified } from 'react-icons/md';
import { FiAward } from 'react-icons/fi';

const Leaderboard = ({ refM }) => {
    const navigate = useNavigate();

    const [authData, setAuthData] = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const [isSmallScreen] = useMediaQuery("(max-width: 820px)")

    const colors = ['yellow.700', 'gray.600', 'orange.700']

    const timeRanges = ["7 days", "30 days", "90 days"]
    const timeRangeMap = {
        "7 days": 7,
        "30 days": 30,
        "90 days": 90
    }
    const [selectedRange, setSelectedRange] = useState("30 days")

    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
        scrollableDiv.scrollTo(0, 0);
        }
        const now = new Date();
        const rangeAgo = new Date(now.getTime() - (timeRangeMap[selectedRange] * 24 * 60 * 60 * 1000));
        // Get the time in seconds since the Unix Epoch
        let secondsSinceEpoch = Math.floor(rangeAgo.getTime() / 1000);
        //for all time we set to 0 so it grabs all leaderboard stats
        if (timeRangeMap[selectedRange] === 0) {
            secondsSinceEpoch = 0
        }

        const fetchData = async () => {
            setLoading(true)
            let response;
            
            try {
                response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/leaderboard?timestamp=${secondsSinceEpoch}`,
                    {
                        method: "GET",
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to retrieve leaderboard", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }

            if (!response.ok) {
                const error = await response.json()
                console.log(error)
                toast.error(error.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setLoading(false)
            }
            else {
                const data = await response.json();
                setData(data.data)
            }

            setLoading(false)
        }

        fetchData()

    }, [authData])

    const newData = async (range) => {
        const oldRange = selectedRange

        if (range === selectedRange) {
            return
        }
        setSelectedRange(range)

        setLoading(true)
        let response;

        const now = new Date();
        const rangeAgo = new Date(now.getTime() - (timeRangeMap[range] * 24 * 60 * 60 * 1000));
        // Get the time in seconds since the Unix Epoch
        let secondsSinceEpoch = Math.floor(rangeAgo.getTime() / 1000);
        //for all time we set to 0 so it grabs all leaderboard stats
        if (timeRangeMap[range] === 0) {
            secondsSinceEpoch = 0
        }

        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/leaderboard?timestamp=${secondsSinceEpoch}`,
                {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve leaderboard", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setSelectedRange(oldRange)
            setLoading(false)
            return
        }

        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            setSelectedRange(oldRange)
            setLoading(false)
        }
        else {
            const data = await response.json();
            setData(data.data)
        }

        setLoading(false)

    }

    //first: AD862E
    //second: 717171
    //third: 8B4B1D
    return (
        <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Leaderboards</title>
                <link rel="canonical" href="https://www.bloomtrades.net/leaderboards" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <List width="100%" mx={'auto'} alignItems='center' className="borderfl">

                <Flex bg='#212121' mt={3} direction='column' fontFamily="Futura" px={5} py={5} _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10}>
                    <Flex flexDirection='row' alignItems="center">

                        <Text fontSize="xl" fontWeight="bold" bgGradient="linear(to-r, green.200, #949FFA)" bgClip="text">
                            Weekly Contest Coming Soon!
                        </Text>
                    </Flex>

                    <Flex flexDirection='row' alignItems="center">
                        <HStack alignItems="baseline">

                            <Text fontSize="5xl" fontWeight="bold" bgGradient="linear(to-r, green.200, #949FFA)" bgClip="text">
                                $100 
                            </Text> 

                            <Text fontSize="md" fontWeight="bold" bgGradient="linear(to-r, green.200, #949FFA)" bgClip="text">
                                USD 
                            </Text> 

                        </HStack>
          
                    </Flex>
                    <Text fontSize="md">
                        Make at least 10 predictions for the given week, 
                        and if you rank in the top 10 for the highest win prediction rate, 
                        you'll qualify for a chance to win a random $100 prize.
                    </Text>

                </Flex>
                <RadioGroup mt={3} mb={3}>
                    <Stack spacing={2} direction='row'>
                        {timeRanges.map((range) => (
                            <Button
                                isDisabled={loading}
                                borderRadius={10}
                                size='sm'
                                key={range}
                                _hover={{bg: selectedRange === range ? "#949ffa" : "#2A2A2A", opacity: selectedRange === range ? 1 : 0.85}}
                                bg={selectedRange === range ? "#949ffa" : ""}
                                opacity={selectedRange === range ? 1 : 0.85}
                                color={selectedRange === range ? '#1C1C1C' : ''}
                                onClick={() => newData(range)}>
                                {range}
                            </Button>
                        ))}
                    </Stack>
                </RadioGroup>

                {
                    loading ? (
                        <SkeletonText noOfLines={8} spacing={4} />
                    ) : (
                            data.length === 0 ? (
                                <Flex bg='#212121' mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10}>
                                    <Text as='b'>No leaderboard entries</Text>
                                </Flex>
                            ) : (
                                    <>
                                        {
                                            data.map((user, index) => {
                                                return <Link to={authData.firebaseuser?.displayName === user.username ? "/profile" : `/user/${user.username}`} key={index} >
                                                    <ListItem _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10} mb={2} bg={colors[index]}>
                                                        <Flex padding={3} alignItems="center" direction='row'>
                                                            <Flex alignItems="center" direction='row' width="50%">
                                                                <Avatar size='sm' bg='' src={user.avatar} />
                                                                <Text ml={2} as='b'>{user.username}</Text>
                                                                { user.user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={5} /> : null }
                                                            </Flex>

                                                            <Spacer />
                                                            <Tag color=''>
                                                                <Text as='b'>{user.win_percent.replaceAll(".00", "")}%</Text>
                                                            </Tag>

                                                            <Spacer />
                                                            <Text as='b'>{formatLargeNumber(user.total_closed_alerts)} <span style={{fontSize: '12px'}}>{isSmallScreen ? "preds" : "predictions"}</span></Text>
                                                        </Flex>
                                                    </ListItem>
                                                </Link>
                                            })
                                        }
                                    </>
                                )

                        )
                }

            </List>
        </Flex>

    )
}

export default Leaderboard