import {
    IconButton,
    Avatar,
    Flex,
    InputGroup,
    Text,
    InputLeftElement,
    Stack,
    Button,
    Input,
    Tag,
    Box,
    SkeletonText,
    InputRightElement,
    List,
    Spacer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Icon
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { AuthContext } from "../App.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet"
import { formatLargeNumber } from '../util/NumberUtils';
import { getLocal } from '../util/GetLocal.js';
import { MdVerified } from 'react-icons/md';

const SearchGlobal = ({ }) => {
    const [authData, setAuthData] = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();

    const [searchVal, setSearchVal] = useState(searchParams.get("q") || "")
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [trendingStock, setTrendingStock] = useState([])
    const [symbolPredCount, setSymbolPredCount] = useState({})

    let typeSearch = searchParams.get("type")
    const location = useLocation();
    if (typeSearch !== "profile" && typeSearch !== "symbol") {
        typeSearch = "profile"
        window.history.replaceState({}, '', `${location.pathname}?type=${typeSearch}`)
    }

    const [type, setType] = useState(typeSearch)


    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        const getTrendingStocks = async () => {
            setSearchLoading(true)
            let response
            let url = `${process.env.REACT_APP_HOST}/api/v1/symbol/trending`
            try {
                response = await fetch(url,
                    {
                        method: "GET",
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)

                return
            }

            if (!response.ok) {
                const error = await response.json()
                console.error(error)

            }
            else {
                const data = await response.json()
                setTrendingStock(data.data)
            }
        }
        getTrendingStocks();
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchVal) {
                setSearchLoading(true)
                let response
                let url = `${process.env.REACT_APP_HOST}/api/v1/users/search?q=${searchVal}`
                if (type === "symbol") {
                    url = `${process.env.REACT_APP_HOST}/api/v1/symbol/search?q=${searchVal}`
                }
                try {
                    response = await fetch(url,
                        {
                            method: "GET",
                            headers: {
                                "ngrok-skip-browser-warning": "any",
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                access_token: await getLocal(authData.firebaseuser)
                            }
                        })
                }
                catch (e) {
                    console.error(e)
                    toast.error(`Failed to query results for '${searchVal}'`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    setSearchResults([])
                    setSearchLoading(false)
                    return
                }

                if (!response.ok) {
                    const error = await response.json()
                    console.error(error)
                    toast.error(error.message, {
                        autoClose: 1000,
                        hideProgressBar: true
                    })
                    setSearchResults([])
                }
                else {
                    const data = await response.json()
                    if (type === "symbol") {
                        setSearchResults(data.data.symbols)
                        setSymbolPredCount(data.data.symbolPredictionCount)
                    }
                    else {
                        setSearchResults(data.data)
                    }
                }
            }
            else {
                setSearchResults([])
            }

            setSearchLoading(false)

        }, 400)

        return () => clearTimeout(delayDebounceFn)

    }, [searchVal])

    const searchOnChange = (e) => {
        setSearchVal(e.target.value)
        window.history.replaceState({}, '', `${location.pathname}?type=${type}&q=${e.target.value}`)
    }

    const typeOnClick = (type) => {
        setType(type)
        setSearchVal("")
        setSearchResults([])
        window.history.replaceState({}, '', `${location.pathname}?type=${type}`)
    }

    return (
        <Flex mx={'auto'} className="cardStackst" fontFamily="Futura" mt={3} direction='column'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Search</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/search?type=${type}`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Flex direction='col' mb={3}>
                <Button
                    onClick={() => typeOnClick("profile")}
                    isDisabled={searchLoading}
                    borderRadius={10}
                    size='sm'
                    _hover={{ bg: type === "profile" ? "#949ffa" : "#2A2A2A", opacity: type === "profile" ? 1 : 0.85 }}
                    bg={type === "profile" ? "#949ffa" : ""}
                    opacity={type === "profile" ? 1 : 0.85} mr={2}
                    color={type === "profile" ? '#1C1C1C' : ''}
                >
                    Profiles
                </Button>
                <Button
                    onClick={() => typeOnClick("symbol")}
                    isDisabled={searchLoading}
                    borderRadius={10}
                    size='sm'
                    _hover={{ bg: type === "symbol" ? "#949ffa" : "#2A2A2A", opacity: type === "symbol" ? 1 : 0.85 }}
                    bg={type === "symbol" ? "#949ffa" : ""}
                    opacity={type === "symbol" ? 1 : 0.85}
                    color={type === 'symbol' ? '#1C1C1C' : ''}
                >
                    Stocks
                </Button>
            </Flex>

            <InputGroup pb={2}>
                <InputLeftElement>
                    <FiSearch color='gray.200' />
                </InputLeftElement>
                <Input focusBorderColor="#949FFA" bg='#212121' autoFocus={true} borderRadius={10} fontWeight="bold" placeholder={type === "profile" ? "Search usernames" : "Search symbols"} value={searchVal} onChange={searchOnChange} />
                <InputRightElement>
                    <IconButton
                        borderRadius={10}
                        zIndex={-1}
                        icon={<FiX />}
                        variant={'ghost'}
                        onClick={() => {
                            setSearchVal("")
                            setSearchResults([])
                            window.history.replaceState({}, '', `${location.pathname}?type=${type}`)
                        }
                        }>
                    </IconButton>
                </InputRightElement>
            </InputGroup>
            <List width="100%" mx={'auto'} alignItems='center' className="borderst">
                {
                    searchLoading ? (
                        <Box mx={'auto'} alignItems='center' fontFamily="Futura" mb={5}>
                            <SkeletonText isLoaded={!searchLoading} mt='4' noOfLines={5} spacing='4' skeletonHeight='2' />
                        </Box>
                    ) : (
                            searchResults.map((item, index) => {
                                return (
                                    type === "symbol" ? (
                                        <Link to={`/symbol/${item.symbol}`} key={index}>
                                            <Flex bg='#212121' direction="column" borderWidth={1} borderRadius={10} mt={2} mb={2} padding={5} _hover={{ opacity: 0.9, cursor: "pointer" }} fontFamily="Futura">
                                                <Flex direction="row" mb={1}>
                                                    <Text pr={2} as='b'>{item.symbol}</Text>
                                                    <Tag lineHeight={1} as='b' colorScheme='purple' size='sm'>
                                                        <Text as='b'>{item.quoteType}</Text>
                                                    </Tag>
                                                    <Spacer />
                                                    <Tag lineHeight={1} as='b' colorScheme={symbolPredCount[item.symbol] > 0 ? 'green' : 'gray'} size='sm'>
                                                        <Text as='b'>{formatLargeNumber(symbolPredCount[item.symbol] || 0)} prediction{symbolPredCount[item.symbol] == 1 ? '' : 's'} past week</Text>
                                                    </Tag>
                                                </Flex>
                                                <Flex direction="row">
                                                    <Text as='b' opacity={0.85}>{item.longname}</Text>
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    ) : (
                                            <Link to={authData.firebaseuser?.displayName === item.username ? "/profile" : `/user/${item.username}`} key={index} >
                                                <Flex bg='#212121' width='100%' borderWidth={1} borderRadius={10} mt={2} mb={2} padding={5} _hover={{ opacity: 0.9, cursor: "pointer" }} fontFamily="Futura">
                                                    <Avatar bg="#212121" src={item.avatar} />
                                                    <Stack spacing={0} marginLeft={5} width="100%" overflowX='auto'>
                                                        <Flex flexDirection='row' alignItems="center">
                                                            <Text as='b'>{item.username}</Text>
                                                            { item.user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={5} /> : null }
                                                        </Flex>

                                                        <Flex pt={1} pb={1}>
                                                            <Tag lineHeight={1} fontWeight='bold' colorScheme='purple' mr={2}>
                                                                {item.subscription_price === 0 ? 'FREE' : `$${(item.subscription_price / 100).toLocaleString(undefined, { maximumFractionDigits: 4 })} USD / month`}
                                                            </Tag>
                                                        </Flex>
                                                        
                                                        <Box style={{ whiteSpace: 'pre-wrap' }} fontFamily='Futura' width="100%">
                                                            <Text opacity={0.85} fontSize='sm'>
                                                                {item.description?.replaceAll("\n", " ")}
                                                            </Text>
                                                        </Box>

                                                        
                                                    </Stack>
                                                </Flex>
                                            </Link>
                                        )
                                )
                            })
                        )
                }
                {
                    type === "symbol" && trendingStock.length > 0 ? (
                        <Flex direction='column' width="100%">
                            <Accordion allowToggle defaultIndex={0} borderBottomColor='#1C1C1C' borderTopColor='#1C1C1C'>
                                <AccordionItem>

                                    <AccordionButton _hover={{ bg: '#1C1C1C' }}>
                                        <Box as="b" flex='1' textAlign='left'>
                                            Trending Stocks
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>

                                    <AccordionPanel pb={4}>
                                        {
                                            trendingStock.map((item, index) => {
                                                return (
                                                    <Link to={`/symbol/${item.symbol}`} key={index}>
                                                        <Flex bg='#212121' direction="column" borderWidth={1} borderRadius={10} mt={2} mb={1} padding={5} _hover={{ opacity: 0.9, cursor: "pointer" }} fontFamily="Futura">
                                                            <Flex direction="row" mb={1}>
                                                                <Text pr={2} as='b'>{item.symbol}</Text>
                                                                <Tag lineHeight={1} as='b' colorScheme='purple' size='sm'>
                                                                    <Text as='b'>{item.quoteType}</Text>
                                                                </Tag>
                                                                <Spacer />
                                                                <Tag lineHeight={1} fontWeight="bold" size='sm' ml={2} colorScheme={item.regularMarketChangePercent.toFixed(2) == 0 ? "gray" : (item.regularMarketChangePercent.toFixed(2) > 0 ? "green" : "red")}>
                                                                    {item.regularMarketChangePercent.toFixed(2) >= 0 ? "+" : ""}{item.regularMarketChangePercent.toFixed(2)}%
                                                            </Tag>


                                                            </Flex>


                                                        </Flex>

                                                    </Link>
                                                )
                                            })
                                        }
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Flex>
                    ) : null
                }

            </List>

        </Flex>
    )
}

export default SearchGlobal