import { useEffect, useState, useContext, useRef } from 'react';
import {
    Flex,
    List,
    Button,
    VStack,
    Avatar,
    Text,
    Divider,
    Spacer,
    Heading,
    Spinner,
    Box,
    useMediaQuery,
    SkeletonCircle,
    SkeletonText,
    Center,
    Tag,
    Icon,
} from '@chakra-ui/react';
import './profileMain.css'

import { FiLock, FiRefreshCcw, FiSettings, FiTrendingUp, FiCopy, FiEye } from 'react-icons/fi'
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";
import { joinDate } from "../util/DateUtils"
import { formatLargeNumber } from "../util/NumberUtils"
import { Helmet } from "react-helmet"
import InfiniteScroll from 'react-infinite-scroll-component';
import Posts from "./Posts"
import { ViewportList } from "react-viewport-list";
import ProfileSettings from './ProfileSettings';
import { AuthContext, GlobalContext } from "../App.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaXTwitter } from "react-icons/fa6"
import UserStats from './UserStats';
import { getLocal } from '../util/GetLocal';
import { MdVerified } from "react-icons/md";

const ProfileMainSelf = ({ refM }) => {
    const [editProfileModal, setEditProfileModal] = useState(false)

    const [loading, setLoading] = useState(true)
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [morePostsLoading, setMorePostsLoading] = useState(false)
    const [morePosts, setMorePosts] = useState(true)
    const [paidPostsFilterLoading, setPaidPostsFilterLoading] = useState(false)
    const [predictionPostsFilterLoading, setPredictionPostsFilterLoading] = useState(false)
    const [openPostsFilterLoading, setOpenPostsFilterLoading] = useState(false)


    const [authData, setAuthData] = useContext(AuthContext)

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [searchParams, setSearchParams] = useSearchParams();

    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");

    const navigate = useNavigate();
    const location = useLocation();

    const username = authData.firebaseuser?.displayName

    const listRef = useRef(null)

    const [initIndex, setInitIndex] = useState(globalData.userInfo === null || globalData.userInfo[username] === undefined ? -1 : searchParams.get("focus") ? (isNaN(+searchParams.get("focus")) ? -1 : +searchParams.get("focus")) : -1)
    const [offset, setOffSet] = useState(globalData.userInfo === null || globalData.userInfo[username] === undefined ? 0 : searchParams.get("offset") ? (isNaN(+searchParams.get("offset")) ? 0 : (+searchParams.get("offset")) * 1) : 0)

    if (globalData.userInfo === null || globalData.userInfo[username] === undefined) {
        window.history.replaceState({}, '', `${location.pathname}`)
    }

    const endPost = useRef(0)

    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');
        if (scrollableDiv) {
          scrollableDiv.scrollTo(0, 0);
        }
        const getProfileInfo = async () => {
            setLoading(true)
            let url = `${process.env.REACT_APP_HOST}/api/v1/userbyname?username=${authData.firebaseuser.displayName}&paidOnly=${globalData.profileSelfSettings.paidPosts}&predictionOnly=${globalData.profileSelfSettings.predictionPosts}&openPosts=${globalData.profileSelfSettings.openPosts}`
            let response
            try {
                response = await fetch(url,
                    {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": "any",
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: await getLocal(authData.firebaseuser)
                        }
                    })
            }
            catch (e) {
                console.error(e)
                toast.error("Failed to fetch profile data", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setLoading(false)
                return
            }

            if (!response.ok) {
                const error1 = await response.json()
                console.log(error1)
                toast.error(error1.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                navigate("/signin") //can't find user means doesn't exist
                setLoading(false)
                return
            }
            else {
                const data = await response.json()
                //setProfileData(data.data[0])

                let tempUserInfo = globalData.userInfo
                tempUserInfo[username] = data.data

                let p = globalData.allPosts
                p[username] = data.data.posts

                setGlobalData({ ...globalData, userInfo: tempUserInfo, allPosts: p })

                setLoading(false)
                if (data.data.posts.length > 0) {
                    endPost.current = data.data.posts[data.data.posts.length - 1].post_id
                }

                if (data.data.posts.length <= 0) {
                    setMorePosts(false)
                }
            }

        }
        if (!authData.firebaseuser) {
            toast.error("Must be signed in to see profile", {
                autoClose: 2000,
                hideProgressBar: true
            })
            navigate("/signin")
            return
        }

        if (!globalData.userInfo[username]) {
            window.history.replaceState({}, '', `${location.pathname}`)
            getProfileInfo()
        }
        else {
            if (globalData.allPosts[username].length > 0) {
                endPost.current = globalData.allPosts[username][globalData.allPosts[username].length - 1].post_id
            }
            setLoading(false)
            setMorePosts(true)
        }

        //for when we disable an account when any of these actions are pressed it will keep them as loading which we don't want
        setRefreshLoading(false)
        setMorePostsLoading(false)

    }, [username, authData])

    const getNextPosts = async () => {
        setMorePostsLoading(true)
        let url = `${process.env.REACT_APP_HOST}/api/v1/posts?username=${authData.firebaseuser.displayName}&endpost=${endPost.current}&paidOnly=${globalData.profileSelfSettings.paidPosts}&predictionOnly=${globalData.profileSelfSettings.predictionPosts}&openPosts=${globalData.profileSelfSettings.openPosts}`
        let response
        try {
            response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            setMorePostsLoading(false)
            toast.error("Failed to retrieve posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            return
        }

        if (!response.ok) {
            const error1 = await response.json()
            console.log(error1)
            toast.error(error1.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            setMorePostsLoading(false)
            return
        }
        else {
            const data = await response.json()

            endPost.current = data.next

            let p = globalData.allPosts
            p[username] = [...globalData.allPosts[username], ...data.posts]
            setGlobalData({ ...globalData, allPosts: p })

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            setMorePostsLoading(false)
        }
    }

    const refreshProfile = async () => {
        setRefreshLoading(true)
        let url = `${process.env.REACT_APP_HOST}/api/v1/userbyname?username=${authData.firebaseuser.displayName}&paidOnly=${globalData.profileSelfSettings.paidPosts}&predictionOnly=${globalData.profileSelfSettings.predictionPosts}&openPosts=${globalData.profileSelfSettings.openPosts}`
        let response
        try {
            response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to fetch profile data", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setRefreshLoading(false)
            return
        }
        if (!response.ok) {
            const error1 = await response.json()
            console.log(error1)
            toast.error(error1.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
            //navigate("/signin") //can't find user means doesn't exist
            setRefreshLoading(false)
            return
        }
        else {
            const data = await response.json()

            let tempUserInfo = globalData.userInfo
            tempUserInfo[username] = data.data

            let p = globalData.allPosts
            p[username] = data.data.posts

            setGlobalData({ ...globalData, userInfo: tempUserInfo, allPosts: p })

            setRefreshLoading(false)
            if (data.data.posts.length > 0) {
                endPost.current = data.data.posts[data.data.posts.length - 1].post_id
            }

            if (data.data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
    }

    const updatePaidFilter = async () => {
        let newFilter = globalData.profileSelfSettings
        newFilter.paidPosts = !newFilter.paidPosts
        setGlobalData({ ...globalData, profileSelfSettings: newFilter })

        setPaidPostsFilterLoading(true)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/posts?username=${authData.firebaseuser.displayName}&paidOnly=${newFilter.paidPosts}&predictionOnly=${globalData.profileSelfSettings.predictionPosts}&openPosts=${globalData.profileSelfSettings.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.paidPosts = !newFilter.paidPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setPaidPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            newFilter.paidPosts = !newFilter.paidPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setPaidPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[username] = data.posts

            setGlobalData({ ...globalData, allPosts: p })

            setPaidPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }

    const updatePredictionFilter = async () => {
        let newFilter = globalData.profileSelfSettings
        newFilter.predictionPosts = !newFilter.predictionPosts
        setGlobalData({ ...globalData, profileSelfSettings: newFilter })

        setPredictionPostsFilterLoading(true)

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/posts?username=${authData.firebaseuser.displayName}&paidOnly=${globalData.profileSelfSettings.paidPosts}&predictionOnly=${newFilter.predictionPosts}&openPosts=${globalData.profileSelfSettings.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.predictionPosts = !newFilter.predictionPosts
            setGlobalData({ ...globalData, profileSelfSettings: newFilter })
            setPredictionPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            newFilter.predictionPosts = !newFilter.predictionPosts
            setGlobalData({ ...globalData, profileSelfSettings: newFilter })
            setPredictionPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[username] = data.posts

            setGlobalData({ ...globalData, allPosts: p })

            setPredictionPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }
        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);
    }
    
    const updateOpenFilter = async () => {
        setOpenPostsFilterLoading(true)
        let newFilter = globalData.profileSelfSettings
        newFilter.openPosts = !newFilter.openPosts
        setGlobalData({ ...globalData, profileSelfSettings: newFilter })

        let response;
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/posts?username=${authData.firebaseuser.displayName}&paidOnly=${globalData.profileSelfSettings.paidPosts}&predictionOnly=${globalData.profileSelfSettings.predictionPosts}&openPosts=${newFilter.openPosts}`,
                {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    }
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to retrieve posts", {
                autoClose: 2000,
                hideProgressBar: true
            })
            newFilter.openPosts = !newFilter.openPosts
            setGlobalData({ ...globalData, feedSettings: newFilter })
            setOpenPostsFilterLoading(false)
            return
        }
        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
            newFilter.openPosts = !newFilter.openPosts
            setGlobalData({ ...globalData, profileSelfSettings: newFilter })
            setOpenPostsFilterLoading(false)
            return
        }
        else {
            const data = await response.json()

            let p = globalData.allPosts
            p[username] = data.posts

            setGlobalData({ ...globalData, allPosts: p })

            setOpenPostsFilterLoading(false)
            if (data.posts.length > 0) {
                endPost.current = data.posts[data.posts.length - 1].post_id
            }

            if (data.posts.length <= 0) {
                setMorePosts(false)
            }
            else {
                setMorePosts(true)
            }
        }

        const myTimeout = setTimeout(() => {
            let nIndex = -1
            let nOff = 0
            if (listRef.current) {
                nIndex = listRef.current.getScrollPosition().index
                nOff = listRef.current.getScrollPosition().offset
            }
            setInitIndex(nIndex)
            setOffSet(nOff)
            window.history.replaceState({}, '', `${location.pathname}?focus=${nIndex}&offset=${nOff}`)
        
        }, 10);

        setOpenPostsFilterLoading(false)
    }

    const copyProfileLinkToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`https://www.bloomtrades.net/user/${authData.firebaseuser.displayName}`);
            toast.success("Copied shareable link to clipboard!", {
                autoClose: 2000,
                hideProgressBar: true
            })
        } catch (err) {
            console.error(err);
            toast.error("Failed to copy shareable link to clipboard", {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }


    if (loading) {
        return <Box mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" mb={20} mt={5}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Profile</title>
                <link rel="canonical" href="https://www.bloomtrades.net/profile" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <SkeletonCircle isLoaded={!loading} size='150px' mx={'auto'} alignItems='center' />
            <SkeletonText isLoaded={!loading} mt='4' noOfLines={5} spacing='4' skeletonHeight='2' />
        </Box>
    }

    if (globalData.userInfo[username] === undefined) {
        return <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" direction='column'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Profile Not Found</title>
                <link rel="canonical" href="https://www.bloomtrades.net/profile" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Heading size="md" mb={2} mt={5} fontFamily="Futura">
                Oops! Profile not found
            </Heading>
            <Text mb={4} opacity={0.85}>This profile doesn't exist, try refreshing page</Text>
        </Flex>
    }

    return (
        <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" direction='column' >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Profile</title>
                <link rel="canonical" href="https://www.bloomtrades.net/profile" />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>

            <List width="100%" mx={'auto'} alignItems='center' className="borderst">
                <VStack alignItems='center' paddingBottom={0} paddingTop={2}>

                    {/*Modals*/}
                    <ProfileSettings isOpen={editProfileModal} setIsOpen={(e) => setEditProfileModal(e)} isSmallScreen={isSmallScreen} />

                    <Flex bg='#212121' width='100%' padding={5} flexDirection="column" borderWidth={1} borderRadius={10} alignItems='center'>
                        <Avatar size='2xl' size='2xl' bg='' mb={2} src={authData.firebaseuser !== null ? authData.firebaseuser.photoURL : ""}></Avatar>

                            <Heading fontFamily="Futura" textAlign='center' fontSize='3xl'>
                                <Flex flexDirection='row' alignItems="center">
                                    {globalData.userInfo[username].username}
                                    {
                                        globalData.userInfo[username].user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={8}/> : null
                                    }
                                </Flex>
                            </Heading>

                        <Box style={{ whiteSpace: 'pre-wrap' }} textAlign='center' fontFamily='Futura' as='b' width="100%" mt={2}>
                            <Text textAlign='center' as='b' opacity={0.85}>{globalData.userInfo[username].description?.replaceAll("\n", " ")}</Text>
                        </Box>

                        <Flex flexDirection='row' width="100%" pb={2} pt={2} as='b'>
                            <Text color="#949FFA"><Link to="/followers">Followers: {formatLargeNumber(globalData.userInfo[username].followerCount)}</Link></Text>
                            <Spacer />
                            <Text color="#949FFA"><Link to="/following">Following: {formatLargeNumber(globalData.userInfo[username].followedCount)}</Link></Text>
                        </Flex>

                        <Flex width='100%' direction={isSmallScreen ? 'column' : 'row'}>
                            {/* some weird bug would make the icon move to the left/right when accordion would open and close, but adding invisible button would make it go away */}
                            <Flex direction={isSmallScreen ? 'row' : 'row'} width='100%'>
                                {
                                    isSmallScreen ? null : <Spacer />
                                }
                                <Button mb={2} mr={2} size='sm' variant='outline' colorScheme='gray' leftIcon={<FiSettings size='18' />} borderRadius={10} width='100%' onClick={() => setEditProfileModal(true)}>Edit Profile</Button>
                                <Spacer />
                                <Button mb={2} size='sm' variant='outline' colorScheme='gray' leftIcon={<FiCopy size='18' />} borderRadius={10} width='100%' onClick={copyProfileLinkToClipboard}>Share Profile</Button>
                            </Flex>
                        </Flex>

                        <Divider mt={2} mb={4} />

                        <Flex direction='column' width='100%'>
                            {
                                globalData.userInfo[authData.firebaseuser.displayName]?.desc_link ? (
                                    <Flex direction='row' mb={2}>
                                        <Text as='b'>Twitter/X</Text>
                                        <Spacer />
                                        <a href={`https://www.twitter.com/${globalData.userInfo[authData.firebaseuser.displayName].desc_link}`} target="_blank">
                                        <Tag colorScheme='gray' as='b'>
                                                <>
                                                    <FaXTwitter size='16' />
                                                    <Text as='b' ml={2}>
                                                        {globalData.userInfo[authData.firebaseuser.displayName].desc_link}
                                                    </Text>
                                                </>
                                            </Tag>
                                        </a>
                                    </Flex>
                                ) : null
                            }
                            <Flex direction='row' mb={2}>
                                <Text as='b'>Joined</Text>
                                <Spacer />
                                <Tag colorScheme='gray'> <Text as='b'>{joinDate(globalData.userInfo[authData.firebaseuser.displayName]?.created_at)}</Text> </Tag>
                            </Flex>
                            <Flex direction='row' mb={2}>
                                <Text as='b'>Predictions</Text>
                                <Spacer />
                                <Tag colorScheme='blue'> <Text as='b'>{globalData.userInfo[authData.firebaseuser.displayName]?.stats.total_closed_posts}</Text> </Tag>
                            </Flex>

                            <Flex direction='row' mb={2}>
                                <Text as='b'>Wins</Text>
                                <Spacer />
                                <Tag colorScheme='green'> <Text as='b'>{globalData.userInfo[authData.firebaseuser.displayName]?.stats.total_win_posts.replaceAll(".00", "") || 0}%</Text> </Tag>
                            </Flex>

                            <UserStats username={authData.firebaseuser.displayName}/>

                        </Flex>
                    </Flex>

                </VStack>

                <Box position='relative' overflowX="auto" width="100%" sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}>
                    <Flex direction="row" mt={3} mb={3}>
                        <Flex whiteSpace="nowrap">
                            <Button size='sm' borderRadius={10} mr={2} color="#949FFA" leftIcon={<FiRefreshCcw size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={refreshLoading} onClick={refreshProfile}>Refresh</Button>
                            <Button size='sm' mr={2} color='red.200' borderRadius={10} leftIcon={<FiLock size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={paidPostsFilterLoading} onClick={updatePaidFilter} opacity={globalData.profileSelfSettings.paidPosts ? 1 : 0.5}>Paid</Button>
                            <Button size='sm' borderRadius={10} mr={2} color="orange.200" leftIcon={<FiTrendingUp size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={predictionPostsFilterLoading} onClick={updatePredictionFilter} opacity={globalData.profileSelfSettings.predictionPosts ? 1 : 0.5}>Prediction</Button>
                            <Button size='sm' borderRadius={10} mr={2} color="cyan.200" leftIcon={<FiEye size='18' />} isDisabled={loading || refreshLoading || paidPostsFilterLoading || predictionPostsFilterLoading || openPostsFilterLoading} isLoading={openPostsFilterLoading} onClick={updateOpenFilter} opacity={globalData.profileSelfSettings.openPosts ? 1 : 0.5}>Open</Button>
                        </Flex>
                    </Flex>
                </Box>
                {
                    globalData.allPosts[username].length === 0 ? ( //globalData.allPosts.filter(item => item.pf.username === username).length
                        <Flex bg='#212121' mb={5} mx={'auto'} alignItems='center' fontFamily="Futura" justify={'center'} py={5} _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10}>
                            <Text as='b'>No posts from '{username}'</Text>
                        </Flex>
                    ) : (
                            <>
                                <InfiniteScroll
                                    scrollableTarget={"scrollableDiv"}
                                    dataLength={globalData.allPosts[username].length}
                                    onScroll={(e) => {
                                        window.history.replaceState({}, '', `${location.pathname}?focus=${listRef.current.getScrollPosition().index}&offset=${listRef.current.getScrollPosition().offset}`)
                                    }}
                                    hasMore={false}
                                    loader={<Flex mt={5} mb={5} justify="center"> <Spinner thickness='4px' speed='1s' size='md' color='#949FFA' /> </Flex>}>
                                    {
                                        <ViewportList ref={listRef} viewportRef={refM} items={globalData.allPosts[username] || []} initialIndex={initIndex} initialOffset={offset} initialPrerender={10}>
                                            {(item, index) => (
                                                <Posts indexCount={index} key={item.post_id} postItem={item} />

                                            )}
                                        </ViewportList>
                                    }
                                </InfiniteScroll>
                                {
                                    morePosts ? <Center mb={3} mt={2}>
                                        <Button borderRadius={10} isLoading={morePostsLoading} size='sm' width="30%" onClick={getNextPosts}>Load More</Button>
                                    </Center> : null
                                }
                            </>
                        )
                }

            </List>

        </Flex>


    )

}

export default ProfileMainSelf