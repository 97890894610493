import {
    Flex,
    Text,
    Divider,
    Heading
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"

const Privacypolicy = () => {
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }
    return (
        <Flex mt={4} direction='column' mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Privacy Policy</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/privacy-policy`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Heading size="md" mb="4">Privacy Policy</Heading>
            <Divider mb='4'/>
            <Text mb="4">
                Welcome to Bloomtrades, Inc. ("Bloomtrades"). Your privacy is important to us. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use our platform. By using Bloomtrades, you agree to the terms of this Privacy Policy.
            </Text>

            <Heading size="md" mt="4" mb="4">Information We Collect</Heading>
            <Text mb="4">
                Account Information: When you create an account on Bloomtrades, we collect your name, email address, username, password, and any other information you provide during registration.
                <br/>
                User Content: We collect the content you post, upload, or transmit on Bloomtrades, including stock predictions, digital content, comments, and other contributions.
                <br/>
                Payment Information: Bloomtrades, Inc. uses Stripe as a third-party payment processor for subscription payment processing. Stripe's use of your personal information is governed by their Privacy Policy. We encourage you to review Stripe's Privacy Policy to understand how your information will be handled by them.
                <br/>
                Automatically Collected Information: We collect information about your use of Bloomtrades, such as IP addresses, browser type, operating system, device information, and usage patterns.
                <br/>
                Cookies and Tracking Technologies: Bloomtrades, Inc. uses cookies to enhance User experience, ensure persistent login sessions, and analyze usage patterns. Cookies are small text files stored on a User's device that contain data about the User. By utilizing cookies, we can provide a more personalized experience for Users and improve the functionality of our App. Users can choose to set their web browser to refuse cookies or to alert them when cookies are being sent. However, if Users do not accept cookies, some parts of the App may not function properly.
            </Text>

            <Heading size="md" mt="4" mb="4">How We Use Your Information</Heading>
            <Text mb="4">
                To Provide and Improve Our Services: We use your information to operate, maintain, and enhance Bloomtrades, to personalize your experience, and to develop new features.
                <br/>
                To Process Payments: We use Stripe to process subscription payments. Your payment information is processed in accordance with Stripe’s privacy policy.
                <br/>
                To Communicate with You: We will use email addresses to respond to inquiries, questions, and other requests from Users. Additionally, we may send emails to Users based on actions within the application, such as subscription, post, replies, like notifications, updates, or important announcements. Users have the option to disable these email notifications through the profile settings page within the App. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc.
                <br/>
                To Enforce Our Policies: We use your information to monitor and enforce our terms and conditions, including investigating and preventing fraudulent activities, abuse, and other violations.            
            </Text>

            <Heading size="md" mt="4" mb="4">Sharing Your Information</Heading>
            <Text mb="4">
                We do not sell, trade, or rent Users' personal identification information to others.
                <br/>
                With Other Users: Your profile information and User Content are visible to other users of Bloomtrades. Be mindful of the information you share publicly.
                <br/>
                With Third-Party Service Providers: We share your information with third-party service providers who help us operate and improve Bloomtrades, such as payment processors, hosting providers, and analytics services. This includes sharing data with Stripe for payment processing and Discord for the automatic posting feature.
                <br/>
                With Law Enforcement and Legal Requests: We may disclose your information to law enforcement authorities or other government officials if required by law or if we believe that such disclosure is necessary to protect our rights or the rights of others.
            </Text>

            <Heading size="md" mt="4" mb="4">How We Protect Your Information</Heading>
            <Text mb="4">
                We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our App.            
            </Text>

            <Heading size="md" mt="4" mb="4">Age Restriction</Heading>
            <Text mb="4">
                Bloomtrades restricts the use of the App to individuals who are 18 years of age and older. We do not knowingly collect personal information from individuals under 18. If you are under the age of 18, please do not use the App or provide any personal information through the App. If we learn that we have collected personal information from a person under the age of 18, we will take steps to delete such information as soon as possible.
            </Text>

            <Heading size="md" mt="4" mb="4">External Links</Heading>
            <Text mb="4">
                Our App may contain links to other websites or third-party services. These links are provided for your convenience and reference. Bloomtrades, Inc. does not control these third-party websites or their privacy practices and policies, which may differ from ours. Therefore, we do not endorse or make any representations about them. We encourage you to review the privacy policies of those third-party websites before providing any personal information.
            </Text>

            <Text mb="4">
                By using our App, you acknowledge and agree to the terms outlined in this Privacy Policy, including the age restriction, disclaimer regarding external links, our use of SSL protocols, the use of cookies to maintain login sessions, and the involvement of Stripe as a third-party payment processor.
            </Text>

            <Text>
                If you have any questions or concerns about this Privacy Policy, please contact us at:&nbsp;
                <Link style={{color: '#949FFA'}} to="mailto:support@bloomtrades.net">support@bloomtrades.net</Link>
            </Text>

            <Text mt="4" mb="4">
                This document was last updated on May 20, 2024.
            </Text>
        </Flex>

    )
}

export default Privacypolicy