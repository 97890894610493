import { Flex, Text, Spacer, ListItem, Stack, Avatar, Tag, useMediaQuery, Icon } from "@chakra-ui/react"
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom"
import { simpleDate } from "../util/DateUtils"


const FollowersItem = ({ user, type }) => {
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");
    const typeText = type === "FOLLOWING" ? "Following" : "Follows"

    return (
        <Link to={`/user/${user.username}`} >
            <ListItem bg="#212121" _hover={{ opacity: 0.9, cursor: "pointer" }} borderWidth={1} borderRadius={10} mb={2}>
                <Flex padding={5} >
                    <Avatar bg='' src={user.avatar} />
                    <Stack spacing={0} marginLeft={5} width="100%" >
                        <Flex flexDirection='row' alignItems='center'>
                            <Text as='b'>{user.username}</Text>
                            { user.user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={5} /> : null }
                            <Spacer />
                            {
                                !isSmallScreen ? (
                                    <Tag size='sm' colorScheme="purple">
                                        {user.sub_total === 0 ? <Text as='b'>{typeText} · FREE</Text> : <Text noOfLines={1} as='b'>{typeText} · ${(user.sub_total / 100).toLocaleString(undefined, { maximumFractionDigits: 4 })} {user.currency} / {user.interval}</Text>}
                                    </Tag>
                                ) : null
                            }

                        </Flex>
                        {
                            isSmallScreen ? (
                                <Flex flexDirection='row' pt={1}>

                                <Tag size='sm' colorScheme="purple">
                                    {user.sub_total === 0 ? <Text as='b'>{typeText} · FREE</Text> : <Text noOfLines={1} as='b'>{typeText} · ${(user.sub_total / 100).toLocaleString(undefined, { maximumFractionDigits: 4 })} {user.currency} / {user.interval}</Text>}
                                </Tag>
                                </Flex>
                            ) : null
                        }
                        <Text noOfLines={1} opacity={0.85} pt={1} fontSize='small' as='b'>
                            Started {simpleDate(user.created_at * 1000)}
                        </Text>
                        {
                            !user.is_paid ? null : (
                                <Text noOfLines={1} opacity={0.85} fontSize='small' as='b'>
                                    {
                                        user.renewal
                                            ? `Renewing on ${simpleDate(user.end_date * 1000)} ${user.sub_total === 0 ? "for FREE" : `for $${(user.sub_total / 100).toLocaleString(undefined, { maximumFractionDigits: 4 })}`}`
                                            : `Ends on ${simpleDate(user.end_date * 1000)}`}
                                </Text>
                            )
                        }

                    </Stack>
                </Flex>
            </ListItem>
        </Link>
    )
}

export default FollowersItem