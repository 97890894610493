import { Flex, Text, Spacer, ListItem, Stack, Avatar, Box, Menu, Portal, MenuList, MenuButton, IconButton, Icon, MenuItem, Spinner } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { differenceInDays } from "../util/DateUtils"
import { AuthContext } from "../App.js"
import { useContext, useState } from "react"
import { FiMoreHorizontal, FiTrash2, FiFlag } from "react-icons/fi"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLocal } from "../util/GetLocal"
import { MdVerified } from "react-icons/md"

const ReplyItem = ({ data, deleteReply }) => {
    const [authData, setAuthData] = useContext(AuthContext)
    const [loading, setLoading] = useState(false)

    const localDeleteReply = async() => {
        setLoading(true)
        await deleteReply(data.reply_id)
        setLoading(false)
    }

    const reportReply = async () => {
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reportreply`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        reply_id: data.reply_id
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to report reply", {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                return
            }
            const error = await response.json()
            console.error(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            toast.success(data.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }

    }

    return (
        <ListItem bg='#212121' position='relative' _hover={{ cursor: "pointer" }} borderWidth={1} borderRadius={10} mb={2} width="100%" opacity={loading ? 0.5 : 1}>
            <Flex padding={5} flexDirection="row">

                <Link to={authData.firebaseuser?.displayName === data.replier_username ? "/profile" : `/user/${data.replier_username}`}>
                    <Avatar bg='' src={data.replier_username === authData.firebaseuser?.displayName ? authData.firebaseuser?.photoURL : data.replier_avatar} />
                </Link>

                <Stack spacing={0} marginLeft={5} width={'100%'} overflowX='auto'>

                    <Flex direction='row' alignItems='center'>
                            <Flex direction='row' alignItems='center' textAlign='center'>
                                <Text as='b'>{data.replier_username}</Text>
                                {
                                    data.user_verified ? <Icon as={MdVerified} color='#949ffa' ml={0.5} boxSize={5} /> : null
                                }
                            </Flex>
                            <Spacer />
                            <Text as='b' fontSize='sm' opacity={0.5}>{differenceInDays(data.created_at * 1000)} ago</Text> {/* was timeAndDate(postItem.created_at) before*/}
                        </Flex>

                    <Box style={{ whiteSpace: 'pre-wrap' }} fontFamily='Futura' as='b' width="100%">
                        <Text pt={1} opacity={0.9}>
                            {data.content}
                        </Text>
                    </Box>

                    <Flex flexDirection='row' paddingBottom={2} pt={3} mx={'auto'} className="postBtn">
                    <Spacer />
                    <Menu>
                        <MenuButton>
                            <IconButton minW='8' icon={<FiMoreHorizontal size='16'/>} variant='outline' size='xs'></IconButton> 
                        </MenuButton>
                        <Portal>
                            <MenuList bgColor="#1C1C1C" position='relative'>
                                {authData.firebaseuser !== null && authData.firebaseuser.displayName === data.replier_username ? <> <MenuItem color="red.200" icon={<FiTrash2 size={20} />} backgroundColor='#1C1C1C' onClick={localDeleteReply}><Text fontFamily="Futura" as='b'>Delete Reply</Text></MenuItem> </> : null}
                                {authData.firebaseuser !== null && authData.firebaseuser.displayName !== data.replier_username ? <> <MenuItem color="red.200" icon={<FiFlag size={20} />} bgColor="#1C1C1C" onClick={reportReply}><Text fontFamily="Futura" as='b'>Report</Text></MenuItem> </> : null}
                            </MenuList>
                        </Portal>

                    </Menu>

                    </Flex>


                </Stack>

            </Flex>
            {(loading) && (
                    <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        zIndex="1000"
                    >
                        <Spinner size="lg" />
                    </Box>
                )}
        </ListItem>
    )
}

export default ReplyItem