import { useContext, useState } from 'react';

import { Button, Flex, Modal, ModalBody, Textarea, Divider, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Center } from "@chakra-ui/react"
import { AuthContext, GlobalContext } from "../App.js"
import ResizeTextarea from "react-textarea-autosize";
import { sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLocal } from '../util/GetLocal.js';

const ReplyModal = ({isSmallScreen, isOpen, setIsOpen, postItem, fromPostPage, setReply, replies}) => {
    const [replyText, setReplyText] = useState("")
    const [replyLoading, setReplyLoading] = useState(false)

    const [authData, setAuthData] = useContext(AuthContext)
    const [globalData, setGlobalData] = useContext(GlobalContext)

    const createReply = async () => {
        setReplyLoading(true)
        if (replyText.trim().length <= 0) {
            toast.error("Cannot reply with empty content", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setReplyLoading(false)
            return
        }
        else if (replyText.length > 280) {
            toast.error("Reply must have less than 280 characters", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setReplyLoading(false)
            return
        }
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/reply`,
                {
                    method: "POST",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser, true)
                    },
                    body: JSON.stringify({
                        content: replyText.replaceAll("\n", "\n"),
                        post_id: postItem.post_id
                        
                    })
                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to reply to post", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setReplyLoading(false)
            return
        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setReplyLoading(false)
                return
            }
            const e = await response.json()
            console.log(e)
            if (e.state === "VERIFY") {
                toast.error(e.message, {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                sendEmailVerification(authData.firebaseuser).then(() => {
                    toast.info("Sending email verification...", {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }).catch((error) => {
                    const errorCode = error.code;
                    console.error(error)
                    toast.error(`Failed to send email verification`, {
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                });
            }
            else {
                toast.error(e.message, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
        }
        else {
            let data = await response.json()
            toast.success(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })

            let posts = globalData.allPosts
            let postIDHasBeenUpdated = new Set()

            for (let key in posts) {
                for (let i = 0; i < posts[key].length; i++) {
                    //so we want to update the likes on the post check if current post id matches whats in globalData.allPosts and only update
                    //if that post hasn't already been updated
                    if (posts[key][i].post_id === postItem.post_id && !postIDHasBeenUpdated.has(posts[key][i].post_id)) {
                        posts[key][i].reply_count++
                        postIDHasBeenUpdated.add(posts[key][i].post_id)
                    }

                }
            }

            if (fromPostPage && !postIDHasBeenUpdated.has(postItem.post_id)) { //so that when we like from post page the UI of the post from post page component gets updated
                postItem.reply_count++
            }

            if (fromPostPage) {
                let replyObj = {
                    replier_avatar: authData.firebaseuser.photoURL,
                    replier_username: authData.firebaseuser.displayName,
                    content: data.replyData.content,
                    created_at: data.replyData.created_at,
                    reply_id: data.replyData.reply_id,
                    post_id: postItem.post_id,
                    user_replied_id: data.replyData.user_replied_id,
                    user_verified: data.replyData.user_verified
    
                }
                setReply([replyObj, ...replies])
            }


            setGlobalData({ ...globalData, allPosts: posts })
            setIsOpen(false)
            setReplyText("")
        }
        setReplyLoading(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={isSmallScreen ? 'full' : 'xl'} autoFocus={false}>
            <ModalOverlay />
            <ModalContent bg='#1C1C1C' boxShadow='lg'>
                <ModalHeader fontFamily="Futura">Reply</ModalHeader>
                <ModalBody>
                    <>
                        <Divider mb={4} />
                        <Flex alignItems="center" width="100%" mt={1} mb={4} fontFamily="Futura">
                            <Textarea
                                isDisabled={replyLoading}
                                value={replyText}
                                onChange={e => setReplyText(e.target.value)}
                                placeholder={`Reply to ${postItem.username}`}
                                fontWeight='bold'
                                minH="7rem"
                                as={ResizeTextarea}
                                focusBorderColor="#949FFA"
                                bg='#212121'
                            />
                        </Flex>
                    </>

                </ModalBody>
                <ModalFooter fontFamily="Futura">
                    <Button borderRadius={10} color='#949FFA' variant='ghost' size='md' onClick={createReply} isLoading={replyLoading}>Reply</Button>
                    <Center height='25px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <Button borderRadius={10} isDisabled={replyLoading} bg="" color='red.200' onClick={() => setIsOpen(false)}>Exit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ReplyModal