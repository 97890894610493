import { Flex } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

function TraderView({ symbol, isAdvanced }) {
  const container = useRef();

  useEffect(() => {
    // Clear the container before appending a new script
    container.current.innerHTML = "";

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;

    if (isAdvanced) {
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.innerHTML = `
        {
          "width": "100%",
          "height": "600",
          "symbol": "${symbol.replaceAll(".TO", "")}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "backgroundColor": "rgba(33, 33, 33, 1)",
          "allow_symbol_change": false,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
    } else {
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
      script.innerHTML = `
        {
          "symbols": [
            [
              "${symbol.replaceAll(".TO", "")}"
            ]
          ],
          "chartOnly": false,
          "width": "100%",
          "height": "600",
          "locale": "en",
          "colorTheme": "dark",
          "autosize": false,
          "showVolume": true,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "line",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "headerFontSize": "medium",
          "lineWidth": 2,
          "lineType": 0,
          "backgroundColor": "rgba(33, 33, 33, 1)",
          "support_host": "https://www.tradingview.com",
          "dateRanges": [
            "1d|1",
            "1m|15",
            "3m|30",
            "12m|60",
            "60m|1W",
            "all|1W"
          ]
        }`;
    }

    container.current.appendChild(script);
  }, [symbol, isAdvanced]);

  return (
    <Flex height="600px" width="100%">
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
    </Flex>
  );
}

export default TraderView;


