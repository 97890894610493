import './App.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter, Link } from "react-router-dom"

import MainInit from "./Main/MainInit"
import { createContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export const GlobalContext = createContext()
export const AuthContext = createContext()

function App() {
    const theme = extendTheme({
        config: {
            useSystemColorMode: true,
            initialColorMode: "dark"
        }
    });
    const globalData = useState({
        userInfo: {}, //key are usernames
        allPosts: {}, //key are /feed, usernames, /stock symbol
        symbolStats: {}, //key are stock symbols
        feedSettings: {
            paidPosts: false,
            predictionPosts: false,
            openPosts: false
        },
        profileSelfSettings: {
            paidPosts: false,
            predictionPosts: false,
            openPosts: false
        },
        symbolFeedSettings: {
            paidPosts: {} //each key will symbol and if symbol is in there means paidOnly=true
        },
        profileSettings: {
            paidPosts: {}, //each key will a user and if user is in there means paidOnly=true
            predictionPosts: {},
            openPosts: {}
        },
        notifications: null,
        notificationSettings: {
            CLOSE: true,
            FOLLOW: true,
            POST: true,
            PRICE_CHANGE: true,
            UPVOTE: true,
            PRICE_CHANGE_FAIL: true,
            REPLY: true
        }
    });

    const authData = useState({
        firebaseuser: null,
        loading: true
    })

    /*
postForHome: [<post object>]
posts: {
    userID/username: {
        profileData: {},
        profilePosts: [],
        paidFilter: boolean false,
        freeFilter: boolean false,
        closedFilter: boolean false,
        openFilter: boolean, false
    },
    ...
},
specificPost: {
    postID: {
        chartData: {},
        postData: {},
        self: boolean,
        replies: []
    }
},
invoices: [<invoice object>],
payments: [<payment object>],
connections: {
    followingCount: <number>,
    followerCount: <number>
    following: [],
    followers: []
},
notifications: {
    notifData: [<notification object>],
    likeFilter: boolean true,
    postFilter: boolean true,
    followFilter: boolean true,
    closedFilter: boolean true
},
//this one will be interesting, I may have to store by each query
//i.e., so during one session user searches "mohid", then clicks post, then clicks search again (should reset if ?q=<> is not there, if it there it should find that query in the globalContext and use that
else just do a fetch for that). so if the user clicks back twice it should show the results for the initial search of "mohid" and not for the last search written to globalContext
search: {
    users: {
        ...quries: []
    },
    stocks: {
        ...quries: []
    },
    posts: {
        ...quries: []
    }
},
userInfo: {
    userID: {
        ...
    }
},
allPosts: { this is because say we are in home and we get posts from mohid, then go to mohid page and we get those posts, then go back to home and refresh and see newer mohid posts, then go back to mohid
            page, we don't want to show those new mohid posts so thats why we differentiate where we got the post from. As when we go back to mohid page we will query the allPosts and check for posts made by 
            mohid, but we don't want those newer posts yet

            BUT, for example we fresh the home page, we will want to add those posts to allPosts AND update any pre existing posts with its new data
            for example we fresh and see a post got new likes we want to update the postID_user with the new post object, etc. This can be done pretty much with a for loop and comparing the postID and setting
            it to the new postObject ONTOP of adding the new posts retrieved with where we got it from i.e., _home, _spec, etc
    postID_<stock symbol>: { //since we are only allowing search by stock and stock page will show posts linked to that stock
        ...postObject
    },
    postID_user: {
        ...postObject
    },
    postID_home: {
        ...postObject
    },
    postID_spec: {
        ...postObject
    }
},

*/

    //allPosts[username..., feed, symbol, spec]
    return (
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <AuthContext.Provider value={authData}>
                    <GlobalContext.Provider value={globalData}>
                        <ToastContainer newestOnTop theme="dark" position={toast.POSITION.BOTTOM_CENTER} />
                        <MainInit />
                    </GlobalContext.Provider>
                </AuthContext.Provider>
            </BrowserRouter>
        </ChakraProvider>
    )

}

export default App;
