import {
    Flex,
    Text,
    Divider,
    Heading,
    Kbd,
    Tag,
    Icon,
    IconButton
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { FiCheckCircle, FiMinusCircle, FiMoreHorizontal, FiSend, FiXCircle } from 'react-icons/fi';

const Info = () => {

    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }

    return (
        <Flex mt={4} direction='column' mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Info</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/info`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Heading size="md" mb="4" fontFamily="Futura">How Bloomtrades Predictions Work</Heading>
            <Divider mb='4'/>

            <Text mb="4">
                To begin creating stock predictions on our platform, you'll need to first <Link style={{color: '#949FFA'}} to="/signup">create an account</Link> and confirm you're at least 18 years old by accepting our <Link style={{color: '#949FFA'}} to="/privacy-policy">privacy policy</Link> and <Link style={{color: '#949FFA'}} to="/terms-and-conditions">terms & conditions</Link>. Once your account is set up, navigate to your profile page either by clicking on your profile icon at the top right of the screen or by accessing it directly <Link style={{color: '#949FFA'}} to="/profile">here</Link>.
            </Text>
            
            <Text mb="4">
                On any page, locate and click the "Prediction" button. You'll then be prompted to enter the stock ticker for which you'd like to make a prediction or select any of the preset stock tickers, along with indicating whether you anticipate the stock price to increase <span style={{color: '#68D391'}}>(bullish)</span> or decrease <span style={{color: '#FC8181'}}>(bearish)</span>. Additionally, you can choose whether to make your prediction post visible to everyone for free or exclusively to your paid subscribers. Feel free to include an image, keeping in mind a maximum file size of 1MB.
            </Text>

            <Text mb="4">
                If you have linked your Discord channel through the "Edit Profile" => "Notifications" section within your profile page, a Discord button will appear. When selected your prediction will automatically be posted in the specified Discord channel by the Bloomtrades Bot, and will post the results of the prediction when you complete it.
            </Text>

            <Text mb="4">
                Once you've filled in all the necessary information, simply press <Kbd>Enter</Kbd> or the "Post" button, and your new prediction post will be listed on your profile page. To finalize your prediction, navigate to your post and click the ellipses button <IconButton mr={2} minW='8' icon={<FiMoreHorizontal size='14'/>} variant='outline' size='xs'></IconButton>From there, select <span style={{color: '#68D391'}}>Complete Prediction</span> to lock in your prediction. Alternatively you can also click <IconButton mr={2} minW='8' icon={<FiSend size='14'/>} variant='outline' size='xs'></IconButton>to lock in your prediction. Additionally, please note that prediction posts will use pre-market and post-market prices if applicable, including when closing a prediction.
            </Text>

            <Text mb="4">
                After completing your prediction, our platform will categorize the outcome as either a Win, Loss, or Neutral, based on the movement of the stock since you made your prediction.
                
                <br/><Tag mt={2} colorScheme='green' size='md'> <Text as='b' mr={1}>Win</Text> <Icon as={FiCheckCircle} /></Tag>
                <br/><Text ml={4}>• You predicted the stock price will increase and the stock rose when you completed the prediction</Text>
                <Text ml={4}>• You predicted the stock price will decrease and the stock dropped when you completed the prediction</Text>

                <Tag mt={2} colorScheme='red' size='md'> <Text as='b' mr={1}>Loss</Text> <Icon as={FiXCircle} /></Tag>
                <br/><Text ml={4}>• You predicted the stock price will increase and the stock dropped when you completed the prediction</Text>
                <Text ml={4}>• You predicted the stock price will decrease and the stock increased when you completed the prediction</Text>

                <Tag mt={2} colorScheme='gray' size='md'> <Text as='b' mr={1}>Neutral</Text> <Icon as={FiMinusCircle} /></Tag>
                <br/><Text ml={4}>• You predicted the stock price will increase or decrease and the stock remained the same when you completed the prediction</Text>

            </Text>

            <Text mb="4">
                Moreover, we also provide you with the percentage difference between the stock price when you made the prediction and when you completed it, offering valuable insights into the accuracy of your prediction.
            </Text>

            <Text mb="4">
                It's important to note that all predictions, whether paid or free, will be accessible in aggregated statistics, viewable on your profile page by anyone. Additionally, please remember that prediction posts can only be <span style={{color: '#FC8181'}}>deleted within 5 minutes</span> of posting.            
            </Text>

            
            
        </Flex>

    )
}

export default Info