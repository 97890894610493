import { jwtDecode } from "jwt-decode";

export async function getLocal(user, forceToken=false) {
    if (!user) {
        return ""
    }
    try {
        const decoded = jwtDecode(user?.accessToken);
        const currentTime = Math.floor(Date.now() / 1000)
        if (currentTime >= decoded.exp || forceToken) {
            const token = await user.getIdToken(true)
            return token
        }
        return user.accessToken        
    }
    catch (e) {
        console.error(e)
        return ""
    }    
}