import { useNavigate } from "react-router-dom";
import {
    useMediaQuery, Flex, Heading, Text, Button
} from '@chakra-ui/react';
import { AuthContext } from "./App.js"
import { useContext } from "react";
import { Helmet } from "react-helmet";

const InvalidPage = () => {
    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery("(max-width: 820px)");
    const [authData, setAuthData] = useContext(AuthContext)

    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }

    return (
        <Flex mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura" direction='column'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Page Not Found</title>
                <link rel="canonical" href="https://www.bloomtrades.net/" />
                <meta name="description" content="Access free market predictions and turn your insights into earning"/>
            </Helmet>
            <Heading size="md" mb={2} mt={5} fontFamily="Futura">
                Oops! Page not found
            </Heading>
            <Text mb={4} opacity={0.85}>It seems like you've reached an unknown page :(</Text>

            <Button size='sm' bg='#949ffa' color='#1C1C1C' _hover={{bg: "#949ffa"}} borderRadius={10} width={isSmallScreen ? "100%" : "75%"} onClick={() => navigate(authData.firebaseuser ? "/feed" : "/") }>
                Go to bloomtrades.net
            </Button>
            
            
        </Flex>
    )
}

export default InvalidPage