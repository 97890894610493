import { useContext, useEffect, useState, useRef } from 'react';

import { Button, Flex, Modal, ModalBody, Spacer, Text, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, Divider, Select, SkeletonText, Icon } from "@chakra-ui/react"
import { simpleDate } from "../util/DateUtils"
import { GlobalContext, AuthContext } from "../App.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiActivity, FiAlertTriangle, FiInfo } from 'react-icons/fi';
import { getLocal } from '../util/GetLocal';

const SubscriptionSettings = ({ setMorePosts, isSmallScreen, isOpen, setIsOpen, tgtData, tgtUsername }) => {

    const [loadingList, setLoadingList] = useState(true)
    const [notifyLoading, setNotifyLoading] = useState(false)
    const [renewalLoading, setRenewalLoading] = useState(false)
    const [unfollowLoading, setUnfollowLoading] = useState(false)
    const [relDataLoading, setRelDataLoading] = useState(false)

    const [globalData, setGlobalData] = useContext(GlobalContext)
    const [authData, setAuthData] = useContext(AuthContext)

    const [relData, setRelData] = useState(null)
    const [confirmUnfollow, setConfirmUnfollow] = useState(false)

    console.log(globalData.userInfo[tgtUsername])
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingList(true);
                setRelDataLoading(true);


                const relDataPromise = fetch(`${process.env.REACT_APP_HOST}/api/v1/relationship?uid=${globalData.userInfo[tgtUsername].id}`, {
                    method: "GET",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser),
                    },
                }).then(async (response) => {
                    if (!response.ok) {
                        const error = await response.json();
                        console.log(error);

                        toast.error(error.message, {
                            autoClose: 2000,
                            hideProgressBar: true
                        })
                        setIsOpen(false);
                    }
                    else {
                        const data = await response.json();
                        setRelData(data.relData);
                        setRelDataLoading(false);
                    }
                });

                await Promise.all([relDataPromise]);
            } catch (error) {
                console.error('Error:', error.message);

                toast.error("Failed to retrieve subscription settings, please try again", {
                    autoClose: 2000,
                    hideProgressBar: true
                })
                setIsOpen(false)
            }
        };

        if (isOpen) {
            fetchData()
        }

        setConfirmUnfollow(false) //reset when modal opens/reopens
    }, [isOpen])


    const updateNotif = async (check) => {
        setNotifyLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updatenotify`,
                {
                    method: "PATCH",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        followed_id: globalData.userInfo[tgtUsername].id,
                        notify: check
                    })

                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to update notification setting", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setNotifyLoading(false)
            return
        }


        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setNotifyLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)

            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[tgtUsername].loggedUserFollowingData.notify = data.notify
            setGlobalData({ ...globalData, userInfo: newUserInfo })

            let newRelData = relData
            newRelData.loggedUserFollowingData.notify = data.notify
            setRelData(newRelData)

            toast.info(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        setNotifyLoading(false)
    }

    const updateRenewal = async (check) => {
        setRenewalLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/updaterenewal`,
                {
                    method: "PATCH",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        renewal: check,
                        followed_id: globalData.userInfo[tgtUsername].id
                    })

                })
        }
        catch (e) {
            console.error(e)
            toast.error("Failed to update subscription renewal", {
                autoClose: 1000,
                hideProgressBar: true
            })
            setRenewalLoading(false)
            return

        }

        if (!response.ok) {
            if (response.status === 429) {
                const error = await response.text();
                console.log(error)
                toast.error(`${error}`, {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                setRenewalLoading(false)
                return
            }
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()

            let newUserInfo = globalData.userInfo
            newUserInfo[tgtUsername].loggedUserFollowingData.renewal = data.renewal
            setGlobalData({ ...globalData, userInfo: newUserInfo })

            let newRelData = relData
            newRelData.loggedUserFollowingData.renewal = data.renewal
            newRelData.loggedUserFollowingData.end_date = data.end_date
            setRelData(newRelData)

            toast.info(data.message, {
                autoClose: 1000,
                hideProgressBar: true
            })
        }
        setRenewalLoading(false)
    }

    const unfollowConfirm = () => {
        setConfirmUnfollow(true)
    }

    const unfollow = async () => {
        setUnfollowLoading(true)
        let response
        try {
            response = await fetch(`${process.env.REACT_APP_HOST}/api/v1/follow?paidOnly=${globalData.profileSettings.paidPosts[tgtUsername] ? true : false}`,
                {
                    method: "DELETE",
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: await getLocal(authData.firebaseuser)
                    },
                    body: JSON.stringify({
                        uid: globalData.userInfo[tgtUsername].id
                    })
                })
        }
        catch (e) {
            console.error(e)

            toast.error("Failed to unfollow user", {
                autoClose: 2000,
                hideProgressBar: true
            })
            setUnfollowLoading(false)
            return
        }

        if (!response.ok) {
            const error = await response.json()
            console.log(error)
            toast.error(error.message, {
                autoClose: 2000,
                hideProgressBar: true
            })
        }
        else {
            const data = await response.json()
            console.log(data)

            //close modal
            //update loggedUserIsFollowing to false
            let tempUserInfo = globalData.userInfo
            tempUserInfo[tgtUsername] = data.newData

            if (tempUserInfo[authData.firebaseuser.displayName]) {
                tempUserInfo[authData.firebaseuser.displayName].followedCount = parseInt(tempUserInfo[authData.firebaseuser.displayName].followedCount) - 1
            }

            let p = globalData.allPosts
            p[tgtUsername] = data.newData.posts

            //loop through data.posts inside that loop, loop through allPosts and any matching post_id set it equal to data.posts[i] 
            for (let i = 0; i < data.newData.posts.length; i++) {
                //only need to update the posts from the user that are free since we are removing the paid posts everywhere else
                if (data.newData.posts[i].is_free) {
                    for (let key in p) {
                        if (key !== tgtUsername) { //we already update allPosts[tgtUsername]/tempUserInfo[tgtUsername] with the unfollowed data posts
                            for (let k = 0; k < p[key].length; k++) {
                                if (p[key][k].post_id === data.newData.posts[i].post_id) {
                                    p[key][k] = data.newData.posts[i]
                                }
                            }
                        }
                    }
                }
            }

            //loop through all globaldata and remove all posts with this user that are paid
            for (let key in p) {
                if (key !== tgtUsername) { //we already update allPosts[tgtUserName] with the unfollowed data posts
                    //if its feed we want to remove all the users posts bc they don't follow them anymore
                    if (key === "/feed") {
                        p[key] = p[key].filter((item, index) => {
                            return !(item.username === tgtUsername)
                        })
                    }
                    //remove all posts where post is from user we are unfollowing and the post is paid (since in profile page we still want to show the free posts,
                    //and for stock feed we still want to show the free posts from that user)
                    else {
                        p[key] = p[key].filter((item, index) => {
                            return !(item.username === tgtUsername && !item.is_free)
                        })
                    }
                }
            }

            setGlobalData({ ...globalData, userInfo: tempUserInfo, allPosts: p })
            setIsOpen(false)
        }
        setUnfollowLoading(false)
        setMorePosts(true)
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={isSmallScreen ? 'full' : 'xl'} autoFocus={false}>
            <ModalOverlay />
            <ModalContent bg='#1C1C1C' boxShadow='lg'>
            <ModalHeader fontFamily="Futura"><Text as="span" color="#949FFA">{tgtUsername}'s</Text> Subscription Settings</ModalHeader>
                <ModalBody>
                    <>
                        <Divider mb={4} />
                        {
                            <Flex fontFamily="Futura" width='100%' flexDirection="column" >


                                {
                                    relDataLoading || !relData ? <SkeletonText noOfLines={8} mt={2} spacing={4} /> : (
                                        <>

                                            <>
                                                {
                                                    relData.loggedUserFollowingData.is_paid ? (
                                                        <>
                                                        <Flex alignItems="center" width="100%">
                                                            <Text fontSize="xl" as='b' textAlign="center">Auto renewal</Text>
                                                            <Spacer />
                                                            <Switch isDisabled={renewalLoading} size='md' isChecked={relData.loggedUserFollowingData.renewal} onChange={(e) => updateRenewal(e.target.checked)} />
                                                        </Flex>
                                                        {
                                                            relData.loggedUserFollowingData.renewal ? <Text as='b' opacity={0.85} fontSize='small'>&emsp;Renewing on {simpleDate(relData.loggedUserFollowingData.end_date * 1000)} for {relData.loggedUserFollowingData.sub_total_subscription === 0 ? "FREE" : `$${relData.loggedUserFollowingData.sub_total_subscription } USD`}</Text>
                                                                : <Text as='b' opacity={0.85} fontSize='small'>&emsp;Ending on {simpleDate(relData.loggedUserFollowingData.end_date * 1000)}</Text>
                                                        }
                                                        <Divider mt={4} mb={4} />
                                                        </>
                                                    ) : null
                                                }
                                            </>

                                            <Flex alignItems="center" width="100%">
                                                <Text fontSize="l" as='b' textAlign="center">Enable Post Notifications?</Text>
                                                <Spacer />
                                                <Switch size='md' isDisabled={notifyLoading} isChecked={relData.loggedUserFollowingData.notify} onChange={(e) => updateNotif(e.target.checked)} />
                                            </Flex>

                                            <Divider mt={4} mb={4} />

                                            <Flex alignItems="center" width="100%">
                                                <Text fontSize="l" as='b' textAlign="center">Following Since: </Text>
                                                <Spacer />
                                                <Text as='b'>{simpleDate(relData.loggedUserFollowingData.created_at * 1000)}</Text>
                                            </Flex>

                                            <Divider mt={4} mb={4} />

                                            <Button size='sm' borderRadius={10} isLoading={unfollowLoading} onClick={confirmUnfollow ? unfollow : unfollowConfirm} variant='outline' color='red.200'>{confirmUnfollow ? "Confirm Unfollow" : "Unfollow"}</Button>
                                            
                                            {
                                                relData.loggedUserFollowingData.is_paid ? (
                                                    <Flex direction='row' align='center' mt={2}>
                                                        <Icon as={FiAlertTriangle} mr={2} color='red.200' fontSize='lg' />
                                                        <Text opacity={0.85} as='b' fontSize='xs'> Unfollowing immediately cancels your paid subscription if active. To regain access to the full profile, you'll need to resubscribe at the full amount. Disable auto-renewal to avoid automatic resubscription at the end of the period.</Text>
                                                    </Flex>
                                                ) : null
                                            }
                                            


                                        </>
                                    )
                                }



                            </Flex>

                        }
                    </>
                </ModalBody>
                <ModalFooter fontFamily="Futura">
                    <Button bg="" borderRadius={10} isLoading={false} color='red.200' onClick={() => setIsOpen(false)}>Exit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}

export default SubscriptionSettings