import {
    Flex,
    Text,
    Divider,
    Heading
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"

const TermsConditions = () => {
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo(0, 0);
    }
    return (
        <Flex mt={4} direction='column' mx={'auto'} alignItems='center' className="cardStackst" fontFamily="Futura">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bloomtrades | Terms and Conditions</title>
                <link rel="canonical" href={`https://www.bloomtrades.net/terms-and-conditions`} />
                <meta name="description" content="Access free market predictions and turn your insights into earnings" />
            </Helmet>
            <Heading size="md" mb="4">Terms and Conditions</Heading>
            <Divider mb='4'/>
            <Text mb="4">
                Welcome to Bloomtrades! These Terms and Conditions ("Terms") govern your access to and use of the Bloomtrades website (referred to as the "Platform"), provided by Bloomtrades, Inc., a corporation incorporated in the state of Delaware ("Bloomtrades," "we," "us," or "our"). By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you may not access or use the Service.            </Text>

            <Heading size="md" mt="4" mb="4">Eligibility</Heading>
            <Text mb="4">
                To create an account on Bloomtrades, individuals must be a minimum of 18 years old. By proceeding to create an account, you affirm and guarantee that you meet this age requirement
            </Text>

            <Heading size="md" mt="4" mb="4">User Content</Heading>
            <Text mb="4">
                All information and content posted by users on the Platform, including stock predictions, discussions, comments, and messages (collectively referred to as "User Content"), are for informational purposes only and should not be considered as financial advice. Bloomtrades, Inc. does not endorse or guarantee the accuracy, reliability, or suitability of any User Content. Bloomtrades, Inc. does not provide tax, legal, financial, or investment advice, nor is it operated by a tax advisor, broker, financial advisor, or investment advisor. Nothing within the service should be interpreted as an offer to sell, a solicitation to buy, or a recommendation for any security. Users should conduct their own research and due diligence before making any financial decisions based on the information provided on the Platform.
                Bloomtrades, Inc. reserves the right to remove any User Content or accounts that violate our policies, including but not limited to content containing:
                <br/>1. Nudity, sexually explicit material, or adult content.
                <br/>2. Harmful acts, violence, self-harm, or illegal activities.
                <br/>3. Hate speech, discrimination, harassment, or threats towards individuals or groups based on race, ethnicity, religion, gender, sexual orientation, disability, or other protected characteristics.
                <br/>4. Personal identifying information of users without their consent.
                <br/>5. Spam and advertising content, including unsolicited promotional materials, links, or commercial messages.
                <br/>6. Content intended to intentionally scam, defraud, or mislead other users.
                <br/>7. False or misleading information that could negatively impact the financial decisions or well-being of users.
                <br/>8. Any other content deemed inappropriate or violating the spirit of our community guidelines.
            </Text>

            <Heading size="md" mt="4" mb="4">Integration with Discord</Heading>
            <Text mb='4'>
                Automatic Posting to Discord: Users have the option to automatically post their Bloomtrades content to a specified Discord server channel. By enabling this feature, users consent to the sharing of their content between Bloomtrades and Discord.
                <br/>User Responsibility: Users are responsible for ensuring that their use of the integration complies with Discord's terms of service and community guidelines. Bloomtrades is not responsible for any actions taken by Discord or other users on Discord in response to the shared content.
                <br/>No Liability for Discord Issues: Bloomtrades shall not be liable for any issues, disruptions, or disputes arising from the use of the Discord integration, including but not limited to data breaches, content removal, or account suspensions by Discord.
            </Text>

            <Heading size="md" mt="4" mb="4">Subscriptions</Heading>
            <Text mb="4">
                User-to-User Subscriptions:  Users on Bloomtrades have the option to subscribe to other users to gain access to their premium content or features ("User-to-User Subscriptions"). By subscribing to another user, you acknowledge and agree to the following:
                <br/>Non-refundable Subscriptions: User-to-User Subscriptions are non-refundable. Once you cancel a subscription to another user, you may lose access to their premium content or features, and you may need to resubscribe to regain access.
                <br/>Payment Processing: User-to-User Subscriptions are processed through Bloomtrades's payment system, which utilizes Stripe as the payment processor. By subscribing to another user, you agree to comply with Stripe's terms and conditions, available at <Link style={{color: '#949FFA'}} to="https://stripe.com/legal">https://stripe.com/legal</Link>.
                <br/>Authorization: By subscribing to another user, you authorize Bloomtrades to charge your chosen payment method through Stripe for the applicable subscription fees and any related taxes. You also authorize Bloomtrades to distribute the subscription fees to the subscribed user, minus any applicable fees or commissions.
                <br/>Subscription Duration: User-to-User Subscriptions may have varying durations, as determined by the subscribed user. You agree to the subscription duration specified by the subscribed user at the time of subscription.
                <br/>User Content: The content and features provided by subscribed users are their sole responsibility. All information and content posted by users on the Platform, including stock predictions, discussions, comments, and messages (collectively referred to as "User Content"), are for informational purposes only and should not be considered as financial advice. Bloomtrades, Inc. does not endorse or guarantee the accuracy, reliability, or suitability of any User Content. Users should conduct their own research and due diligence before making any financial decisions based on the information provided on the Platform.
                <br/>Payment Information: You agree to provide accurate and complete payment information, and you authorize Bloomtrades to share such information with Stripe for the purpose of processing payments.
                <br/>Stripe's Terms and Conditions: You acknowledge and agree that your use of Stripe's services for subscription payments is subject to Stripe's terms and conditions, and Bloomtrades, Inc. shall not be responsible for any disputes, liabilities, or obligations arising from your use of Stripe's services.
                <br/>Platform Fee: Bloomtrades will take a 20% platform fee on all subscriptions, and the remaining will be paid to the subscribed user. Payouts will be processed and distributed in accordance with Stripe's payment processing system.
            </Text>

            <Heading size="md" mt="4" mb="4">Intellectual Property</Heading>
            <Text mb="4">
                All content and materials available on the Platform, including but not limited to text, graphics, logos, images, and software, are the property of Bloomtrades, Inc. or its licensors and are protected by intellectual property laws. Users are not permitted to use, copy, reproduce, modify, distribute, or create derivative works based on any content from the Platform without prior written consent from Bloomtrades, Inc.
            </Text>

            <Heading size="md" mt="4" mb="4">User Conduct</Heading>
            <Text mb="4">
                Compliance with Laws: Users agree to comply with all applicable laws, regulations, and legal obligations when using Bloomtrades. Users shall not engage in any conduct that violates the rights of others or infringes on any third-party rights, including but not limited to intellectual property rights, privacy rights, and publicity rights.
                <br/>Respectful and Lawful Conduct: Users agree to engage in respectful, lawful, and ethical conduct when using Bloomtrades. Prohibited conduct includes, but is not limited to:
                <br/>1. Harassment and Abuse: Users shall not engage in harassment, bullying, intimidation, or abuse of other users. This includes but is not limited to using derogatory language, making threats, or engaging in personal attacks.
                <br/>2. Hate Speech and Discrimination: Users shall not promote or condone hate speech, discrimination, or intolerance based on race, ethnicity, religion, gender, sexual orientation, disability, or other protected characteristics.
                <br/>3. Illegal Activities: Users shall not engage in any illegal activities or encourage others to do so. This includes but is not limited to promoting or facilitating illegal drug use, violence, fraud, or other criminal activities.
                <br/>4. Impersonation: Users shall not impersonate any person or entity, including but not limited to Bloomtrades staff, moderators, or other users. Impersonation includes using fake identities, misleading profile information, or attempting to deceive others.
                <br/>5. Spam and Misinformation: Users shall not engage in spamming, phishing, or the dissemination of false or misleading information. This includes but is not limited to sending unsolicited messages, posting irrelevant or repetitive content, or spreading hoaxes or rumors.
                <br/>6. Manipulation and Fraud: Users shall not engage in any form of manipulation, fraud, or deception to gain unfair advantage or harm others. This includes but is not limited to manipulating market prices, engaging in pump-and-dump schemes, or posting false information for financial gain.
                <br/>7. Unauthorized Access: Users shall not attempt to gain unauthorized access to Bloomtrades accounts, systems, or networks. This includes but is not limited to hacking, phishing, or using automated tools or scripts to bypass security measures.
                <br/><br/>Reporting Violations: Users are encouraged to report any violations of these User Conduct guidelines to Bloomtrades. Bloomtrades reserves the right to take appropriate action, including but not limited to removing offending content, suspending or terminating user accounts, and cooperating with law enforcement authorities, as necessary.
                <br/><br/>Consequences of Violations: Users acknowledge that violations of these User Conduct guidelines may result in disciplinary action, including warnings, account suspension, or permanent account termination, at the discretion of Bloomtrades. Repeat offenders may be subject to more severe penalties.

            </Text>

            <Heading size="md" mt="4" mb="4">Privacy</Heading>
            <Text mb="4">
                By using the Platform, you consent to the collection, use, and disclosure of your personal information as described in our <Link style={{color: '#949FFA'}} to="/privacy-policy">Privacy Policy</Link>. Bloomtrades, Inc. takes the privacy and security of user data seriously and implements appropriate measures to protect it.
            </Text>

            <Heading size="md" mt="4" mb="4">Disclaimer of Warranties</Heading>
            <Text mb="4">
                General Disclaimer: Bloomtrades makes no warranties or representations about the accuracy, reliability, completeness, or timeliness of the content available on the platform. Users acknowledge that any reliance on such content is at their own risk.
                <br/>No Financial Advice: Bloomtrades does not provide financial, investment, or trading advice. The information and content provided on the platform, including but not limited to stock predictions, discussions, and comments, are for informational purposes only and should not be considered as financial advice. Users should conduct their own research, seek professional advice, and exercise caution before making any financial decisions.
                <br/>No Endorsement: Bloomtrades does not endorse, guarantee, or warrant the accuracy, reliability, suitability, or quality of any content, products, services, or information provided by users or third parties on the platform. Any views, opinions, or recommendations expressed by users are solely their own and do not reflect the views of Bloomtrades.
                <br/>No Warranty of Availability: Bloomtrades does not warrant that the platform will be available, uninterrupted, secure, or error-free at all times. Users acknowledge that access to the platform may be interrupted or restricted due to technical issues, maintenance, or other factors beyond Bloomtrades's control.
                <br/>No Warranty of Compatibility: Bloomtrades does not warrant that the platform will be compatible with all devices, browsers, or operating systems. Users acknowledge that certain features or functionalities of the platform may require specific hardware, software, or internet connectivity, and Bloomtrades shall not be responsible for any compatibility issues.
                <br/>No Warranty of Results: Bloomtrades does not guarantee any specific results or outcomes from the use of the platform. Users acknowledge that trading and investing in financial markets involve inherent risks, and past performance is not indicative of future results. Bloomtrades shall not be liable for any losses, damages, or liabilities arising from users' reliance on the platform or its content.
                <br/>No Warranty of Security: Bloomtrades implements reasonable security measures to protect user data and information. However, Bloomtrades does not warrant that the platform will be free from security breaches, unauthorized access, or data loss. Users acknowledge the inherent risks of transmitting data over the internet and agree to use the platform at their own risk.
                <br/>No Warranty of Third-Party Services: Bloomtrades may integrate or link to third-party services, websites, or applications for additional features or functionalities. Bloomtrades does not warrant the accuracy, reliability, or quality of any third-party services, and users acknowledge that their use of such services is subject to the terms and conditions of the respective third parties.
            </Text>

            <Heading size="md" mt="4" mb="4">Limitation of Liability</Heading>
            <Text mb="4">
                No Liability for User Content: Bloomtrades shall not be liable for any User Content posted, uploaded, or transmitted on the platform. Users acknowledge that they are solely responsible for their User Content, and Bloomtrades does not endorse, guarantee, or warrant the accuracy, reliability, or legality of such content.
                <br/>No Liability for Third-Party Content: Bloomtrades shall not be liable for any third-party content, products, services, or information accessed or obtained through the platform. Users acknowledge and agree that Bloomtrades does not endorse or control third-party content, and any interactions with third parties are at the user's own risk.
                <br/>No Liability for Technical Issues: Bloomtrades shall not be liable for any technical issues, interruptions, or disruptions to the platform's availability, functionality, or performance. Users acknowledge that the platform may experience downtime, errors, or delays due to technical issues, maintenance, or other factors beyond Bloomtrades's control.
                <br/>No Liability for Losses or Damages: Bloomtrades shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use of the platform. This includes but is not limited to damages for loss of profits, data, reputation, or goodwill, even if BloomTrades has been advised of the possibility of such damages.
                <br/>No Liability for Investment Decisions: Bloomtrades shall not be liable for any investment decisions made by users based on the information or content provided on the platform. Users acknowledge that trading and investing in financial markets involve inherent risks, and Bloomtrades does not provide financial, investment, or trading advice.
                <br/>No Liability for Security Breaches: Bloomtrades shall not be liable for any unauthorized access, security breaches, or data breaches affecting user accounts or personal information. While BloomTrades implements reasonable security measures to protect user data, users acknowledge the inherent risks of transmitting data over the internet.
                <br/>Maximum Liability: In no event shall Bloomtrades's total liability to any user exceed the amount paid by the user, if any, for accessing or using the platform during the twelve (12) months prior to the event giving rise to the liability.
                <br/>Exceptions to Limitation: Some jurisdictions do not allow the exclusion or limitation of liability for certain types of damages. In such jurisdictions, the liability of Bloomtrades shall be limited to the maximum extent permitted by law.
            </Text>

            <Heading size="md" mt="4" mb="4">Indemnification</Heading>
            <Text mb="4">
                User's Obligation to Indemnify: Users agree to indemnify, defend, and hold harmless Bloomtrades, Inc. and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with any breach of these Terms and Conditions by the user, including but not limited to claims related to User Content, unauthorized use of the platform, or violation of applicable laws or regulations.                
                <br/>Defense and Settlement: Bloomtrades reserves the right to assume the exclusive defense and control of any matter subject to indemnification by the user, in which case the user agrees to cooperate with BloomTrades in asserting any available defenses. BloomTrades also reserves the right to settle any claims or disputes without the user's consent, provided that Bloomtrades will not agree to any settlement that imposes any liability or obligation on the user without the user's prior written consent.
                <br/>Notice of Claim: The user agrees to promptly notify Bloomtrades of any claims, demands, or legal proceedings that may give rise to indemnification obligations under these Terms and Conditions. Failure to provide such notice shall not relieve the user of its indemnification obligations, except to the extent that the failure to provide notice materially prejudices Bloomtrades's ability to defend or settle the claim.
                <br/>Limitation of Liability: Bloomtrades's total liability for indemnification under this section shall be subject to the limitations set forth in the "Limitation of Liability" section of these Terms and Conditions.

            </Text>

            <Heading size="md" mt="4" mb="4">Governing Law and Jurisdiction:</Heading>
            <Text mb="4">
                Governing Law: These Terms and Conditions, and any disputes arising out of or related to these terms or the Platform, shall be governed by and construed in accordance with the laws of the State of Delaware, United States, without regard to its conflict of law principles.
                <br/>Jurisdiction: Any legal action or proceeding arising out of or related to these Terms and Conditions or the use of the Platform shall be brought exclusively in the federal or state courts located in the State of Delaware. By agreeing to these Terms and Conditions, you hereby consent to the jurisdiction and venue of such courts and waive any objection to such jurisdiction or venue on grounds of forum non conveniens or otherwise.
                <br/>Waiver of Jury Trial: You agree to waive any right to a jury trial in any legal proceeding arising out of or related to these Terms and Conditions or the use of the Platform.
                <br/>Class Action Waiver: You agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action.
            </Text>

            <Heading size="md" mt="4" mb="4">Modification of Terms</Heading>
            <Text mb="4">
                Bloomtrades, Inc. reserves the right to modify or update these Terms and Conditions at any time without prior notice. Continued use of the Platform after any such changes shall constitute your consent to such changes.
            </Text>

            <Text mb="4">
                By accessing or using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, you may not access or use the Platform.            
            </Text>

            <Text>
                If you have any questions or concerns about the Terms and Conditions, please contact us at:&nbsp;
                <Link style={{color: '#949FFA'}} to="mailto:support@bloomtrades.net">support@bloomtrades.net</Link>
            </Text>

            <Text mt="4" mb="4">
                This document was last updated on May 20, 2024.
            </Text>
        </Flex>

    )
}

export default TermsConditions