// Import the necessary Firebase services and components
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

    const firebaseConfig = {
        apiKey: "AIzaSyC1ju58zk9wStBgEYWQg11O5SEfGlfTc1o",
        authDomain: "bloomtrades-prod.firebaseapp.com",
        projectId: "bloomtrades-prod",
        storageBucket: "bloomtrades-prod.appspot.com",
        messagingSenderId: "53295557743",
        appId: "1:53295557743:web:3630013ead5b7f4b68f19a"
    };



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get the Firebase Authentication service instance
const auth = getAuth(firebaseApp);

export { auth, firebaseApp };